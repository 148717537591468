const seller = {
	home: {
		tips: '还差 1 步认证，你的店铺就可以被消费者看到了',
		subtips: '完成认证，立即开始店铺经营',
		attestation: '立即认证',
		pending: '待付款',
		accepted: '待发货',
		delivered: '已发货',
		finishOrder: '成功的订单',
		afterSales: '待售后',
		evaluated: '待评价',
		violation: '违规',
		dataTitle: '店铺数据',
		datatime: '数据更新时间:',
		paymentAmount: '支付金额',
		visitors: '访客数',
		subOrders: '支付子订单数',
		mainOrders: '支付主订单数',
		cartsGoods: '加购商品数',
		views: '浏览量',
		cartsNumber: '加购人数',
		unitPrice: '客单价',
		buyers: '支付买家数',
		yesterday: '昨日',
		into: '进店',
		alipay: '支付宝',
		credit: '店铺信用等级',
		points: '未开始积分',
		score: '店铺动态评分',
		describe: '描述',
		service: '服务',
		logistics: '物流',
		deposit: '保证金',
		isPay: '暂无需缴纳',
		bound: '已绑定',
		unbound: '未绑定',
		aboutuu: '关于优优',
		uulianLink: '优优官网',
		copyright: 'Copyright',
		copyrightText: '© Uulian Inc.',
		privacyPolicy: '《安徽培众隐私政策》',
		licenseAgreement: '《软件许可使用协议》'
	},
	shop: {
		title: '我要开店',
		openText1: '开店成功啦',
		openText2: '完成任务即刻开店',
		openTitle1: '开店任务',
		openTitle2: '新手体验',
		openItem1: '手机认证',
		openItem2: '身份证认证',
		openItem3: '身份证正反面',
		openTips1: '正面',
		openTips2: '反面',
		'openBase1-1': '发布商品',
		'openBase1-2': '有在售商品可有效提升店铺曝光能力，快去发布吧',
		'openBase2-1': '店铺基础信息',
		'openBase2-2': '有趣易读的店铺名，能让消费者快速搜索到您的店铺',
		openAdopt: '恭喜您，认证全部通过啦',
		infoPane1: '基础信息',
		'infoTips1-1': '180天内店铺名称一共可以修改3次,您当前还可以修改',
		'infoTips1-2': '次，请谨慎填写',
		infoTips2: '请确保您填写的地址信息真实有效',
		infoBar1: '效果预览',
		'infoBar1-1': '网页版店铺卡片',
		'infoBar1-2': '卖家:',
		infoBar2: '手机版店铺卡片',
		'infoBar2-1': '好评率',
		'infoBar2-2': '商品图片',
		infoPane2: '域名设置',
		domainTips1: '您尚未完成认证，请完成认证后再尝试设置，店铺域名一共可以修改4次，请谨慎填写',
		domainBar1: '效果预览',
		'domainBar1-1': '电脑端浏览器访问',
		'domainBar1-2': '首页',
		'domainBar1-3': 'UU联'
	},
	studyCenter: {
		title: '学习中心'
	},
	taskCenter: {
		title: '任务中心'
	},
	knowledgeCenter: {
		title: '知识中心'
	},
	helpCenter: {
		title: '帮助中心'
	},
	ranking: {
		tipsTitle: '得分说明',
		tipsText1: '1、学生的成绩值由系统评分和教师评分之和组成；',
		tipsText2: '2、系统评分值最高为79分，教师评分值最高为21分，总和为100分。',
	},
	report: {
		realName: '学生名:',
		userName: '账号:',
		shopName: '店铺名:',
		system: '系统',
		teacher: '教师',
		total: '总计',
		paneName1: '系统评分信息',
		paneName2: '教师评分信息',
		scoreText: '评分:',
	},
	decorate: {
		tabsJson: [
			{ label: '推荐(首页)', value: '1' },
			// { label: '全部宝贝', value: '2' },
			// { label: '宝贝分类', value: '3' },
			// { label: '宝贝分类', value: '4' },
			// { label: '大促承接页', value: '5' },
			{ label: '店铺印象', value: '6' },
		],
		cateMenu: [
			{ name: '宝贝分类', path: '/home/decorate/category' },
			// { name: '宝贝管理', children: [
			// 	{ name: '全部宝贝', },
			// 	{ name: '未分类宝贝', },
			// 	{ name: '已分类宝贝', children: [
			// 		{ name: '移动', },
			// 	] }
			// ]}
		],
		cateJson: {1:'手工分类',2:'自动分类'},
		cateTips1: '类目名称不能为空!',
		cateTips2: '类目名称不能重复!',
		cateTips3: '类目:',
		cateTips4: '子类目名称不能重复!',
		cateTips5: '您删除的父分类:',
		cateTips6: '中所有的子分类都必须被删除,请重新选择!'
	},
	pcdecorate: {
		tabsJson: [
			{ label: '基础页', value: 'base' },
			{ label: '宝贝详情页', value: 'detail' },
			{ label: '宝贝列表页', value: 'list' },
			{ label: '自定义页', value: 'diy' },
			{ label: '宝贝分类', value: 'cate' }
		],
		pageMenu: [
			{ label: '模块', icon: 'el-icon-menu' }
		],
		pageTips1: '选择所需模块，并拖动至相应位置',
		pageTips2: '以上为页头区域',
		pageTips3: '以下为页尾区域',
		basicModule: '基础模块',
		addModule: '可添加模块',
		footerTitle: '店铺尾部',
		shopHeader: '店铺页头',
		shopFooter: '店铺页尾',
		moduleTips: '，可以用来展示店铺特色的宝贝、活动。',
		copyright: '© 2014-现在 安徽培众教育科技有限公司 版权所有',
		moduleDragg: '请拖入模块',
		unitPx: '单位：像素',
		previewTips: '您当前处于预览模式，页头超过150px部分在预览和发布时将不显示，宝贝详情页不支持页面背景。',
		shop: '店铺',
		joinShop: '进入店铺'
	},
	material: {
		pagePane1: '素材上传',
		pagePane2: '我的素材',
		shopPane1: '商品素材',
		shopPane2: '店铺素材',
		shopTips1: '完善活动商品素材，将有机会获取大促公域流量！',
		'shopSteps2-1': '3步完成素材提报：',
		'shopSteps2-2': '按要求提交素材',
		'shopSteps2-3': '素材审核中',
		'shopSteps2-4': '审核反馈（通过/修改意见）',
		shopEffect: '店铺印象',
		shopTagOption: [ '个性店主', '行业资深', '工厂店', '原产地', '独特工艺' ],
		shopScene: '素材中心已经覆盖场景',
		shopSceneOption: [ '猜你喜欢', '有好货', '聚享购', '大促会场', 'iFashion', '全球时尚', '极有家', '亲宝贝', '汇吃', '美妆学院', '酷玩星球', '每日好店', '首页焦点推荐', '云主题', '店铺宝贝', '订单详情' ],
		shopTableTips1: '用简要、直白的语言，描述店铺最吸引新顾客的一个细节或特点建议用对话式的白话语言去写。避免不走心的俗、大、空、口水套话。',
		shopTableTips2: '非白底 无文字 海报图 画面核心内容居中（便于裁剪） 容量:小于500K 格式:JPG/JPEG',
		retrieve: '图片回收站',
		statusOption: [
			{ label: '全部文件', value: '-1' },
			{ label: '全部图片', value: '0' }
		],
		checkText1: '已选中',
		checkText2: '个文件',
		uploadTitle: '欢迎使用图片空间',
		uploadTo: '上传到',
		uploadText1: '将图片拖放到此处上传，或点击',
		uploadText2: '上传',
		uploadText3: '为了保证图片的正常使用，仅支持3M以内jpg、jpeg、gif、png格式图片上传。',
		uploadText4: '支持选择多张图片上传，支持拖拽文件上传。',
		uploadText5: '查看图片上传教程说明',
		uploadText6: '将文件拖放到此窗口即可上传，或点击',
		uploadText7: '仅支持3MB以内jpg、jpeg、gif、png格式图片上传',
		uploadText8: '尺寸不符合要求',
		parentFolder: '所属上级文件夹',
		groupName: '分组名称',
		renameFolder: '文件夹重命名',
		cropperText1: '裁剪尺寸 (输入后按回车生效)',
		cropperText2: '宽',
		cropperText3: '高',
		cropperText4: '注意：支持裁剪最小宽度',
		cropperText5: '最小高度',
		cropperText6: '固定宽高比例为'
	},
	pageEditor: {
		pagecurr: '当前页面：',
		follow: '+关注',
		fansNum: '粉丝数',
		tips1: '该视频装修成功后将展示在搜索-店铺下，建议精良制作。',
		tips2: '原店铺二楼的视频和内容可装修至此模块。',
		tips3: '建议装修发票/服务/物流等相关说明。',
		userName: '掌柜名',
		regionName: '所在地',
		qualifier: '资质',
		agreement: '已签署消保协议',
		drawerTitle: '页面容器',
		drawerText: '拖入页面容器到画布区域',
		basket: '将模块放置于此',
		salesList: '销量榜',
		collectList: '收藏榜',
		newList: '新品榜',
		interestNum: '个客官感兴趣',
		salesNum: '人付款',
		layoutTitle: '布局管理',
		panelTitle1: '模块基础内容',
		wpTips1: '请上传宽度1200px，高度600px～2000px的图片，支持jpg/png格式，大小不超过2兆。展示的所有图片尺寸需一致（即第一张上传的图片的尺寸）如需修改展示图片尺寸，需将当前图片都删除后才能进行重制。',
		wpTips2: '请上传宽度1200px，高度120px～2000px的图片，支持jpg/png格式，大小不超过2兆。',
		wpTips3: '请上传比例为16:9、清晰度720P以上的视频。视频建议时长10秒~10分钟',
		wpTips4: '当商品不足3个的时候，则该模块店铺首页不展示。',
		wpTips5: '请上传宽度1200px，高度376px的图片，支持jpg/png格式，大小不超过2兆。',
		officialStyle: '官方样式',
		row: '排',
		basicSetupOption: [
			{ label: '算法排序', value: '1' },
			{ label: '固定顺序', value: '2' }
		],
		subUndertakeOption: [
			{ label: '自定义链接', value: '1' },
			{ label: '微详情页(MiniDetail页)', value: '2' }
		],
		vedioSubpageOption: [
			{ label: '视频流承接页（官方推荐)', value: '1' },
			{ label: '自定义链接', value: '2' }
		],
		goodsStockOption: [
			{ label: '手动添加（按顺序显示宝贝，超出数量的部分不展示）', value: '1' },
			// { label: '千人千面', value: '2' }
		],
		couponAddOption: [
			{ label: '手动添加', value: '1' },
			{ label: '自动添加（暂不支持）', value: '2' }
		],
		couponFissionOption: [
			{ label: '手动添加', value: '1' },
			{ label: '自动添加', value: '2' }
		],
		'wpText1-1': '您已添加',
		'wpText1-2': '个宝贝',
		'wpText2-1': '满',
		'wpText2-2': '元使用'
	},
	goods: {
		stepsOption: ['上传预填材料','完善商品信息'],
		title1: '基础信息',
		title2: '销售信息',
		title3: '支付信息',
		title4: '物流信息',
		title5: '图文描述',
		tips1: '请上传一张高清正面商品主图，最多支持上传四张商品正面图，其中一张需要为商品主图的白底图',
		tips2: '请确认或修改商品类目',
		tips3: '建议将以下推荐词添加至标题中以提升您的商品表达，请只添加符合您商品描述的内容：',
		tips4: '即日起，标题中请勿使用制表符、换行符。若填入制表符、换行符，系统将自动替换成空格',
		tips5: '错误填写宝贝属性，可能会引起宝贝下架或搜索流量减少，影响您的正常销售，请认真准确填写！',
		tips6: '最多支持选择10项分类，',
		tips7: '请选择或直接输入主色，标准颜色可增加搜索/导购机会！',
		tips8: '属性图上传功能更新，亲可以在sku设置中找到图片上传入口哦！',
		tips9: '在标题栏中输入或选择内容可以进行筛选和批量填充',
		tips10: '总数量范围是1件~100000000件之间，默认为1件',
		tips11: '宝贝主图大小不能超过3MB；700*700 以上图片上传后宝贝详情页自动提供放大镜功能。第五张图发商品白底图可增加首页曝光机会',
		tips12: '1.尺寸：此处可使用1：1或16：9比例视频',
		tips13: '2.时长：≤60s，建议30秒以内短视频可优先在爱逛街等推荐频道展现',
		tips14: '3.内容：突出商品1-2个核心卖点，不建议电子相册式的图片翻页视频',
		tips15: '长图横竖比必须为2：3，最小长度为480，建议使用800*1200，若不上传长图，搜索列表、市场活动等页面的竖图模式将无法展示宝贝！',
		tips16: '定时上架的商品在上架前请到“仓库中的宝贝”里编辑商品。',
		text1: '当前类目',
		'text2-1': '抱歉，没有找到与关键字“',
		'text2-2': '”相关的类目',
		text3: '发布历史',
		text4: '运费模板',
		text5: '宝贝长图',
		uploadTips1: '商品主图',
		uploadTips2: '商品正面图',
		checkCate: '已选类目',
		applyOption: [
			{ label: '男', value: 1 },
			{ label: '女', value: 2 },
			{ label: '青年(18-40周岁)', value: 3 },
			{ label: '中年(40-60周岁)', value: 4 },
			{ label: '老年(60周岁以上)', value: 5 },
			{ label: '儿童(18周岁以下)', value: 6 },
			{ label: '情侣', value: 7 },
			{ label: '学生', value: 8 },
			{ label: '青少年', value: 9 },
			{ label: '通用', value: 10 }
		],
		typeOption: [
			{ label: '全新', value: '1' },
			{ label: '二手', value: '2' }
		],
		purchaseOption: [
			{ label: '中国内地(大陆)', value: '1' },
			{ label: '中国港澳台地区及其他国家和地区', value: '2' }
		],
		payOptipn: [
			{ label: '一口价(普通交易模式)', value: '1' },
			{ label: '预售模式', value: '2' }
		],
		deductOptipn: [
			{ label: '买家拍下减库存', value: '1' },
			{ label: '买家付款减库存', value: '2' }
		],
		scaleOption: [
			{ label: '1:1或16:9', value: '1' },
			{ label: '3:4', value: '2' }
		],
		shelvesOption: [
			{ label: '立刻上架', value: 1 },
			// { label: '定时上架', value: 2 },
			{ label: '放入仓库', value: 0 }
		]
	},
	goodsList: {
		'tips1-1': '1、若宝贝90天内无编辑、流量或出售的行为，系统将把宝贝作为滞销商品下架至',
		'tips1-2': '滞销下架宝贝',
		'tips2-1': '2、您好，若需要管理全店或商品维度的销售范围（原区域限售），',
		'tips2-2': '请点击此链接去设置',
		'tips3-1': '3、亲，我们想开展一个宝贝素材图共享服务，期望通过这项服务提高商家发品质量和成功率，希望亲能花1分钟的时间参与问卷调研，您的建议对我们特别重要：',
		'tips3-2': '点击参与',
		tabsOption: [
			{ label: '全部宝贝', value: 'all' },
			{ label: '出售中的宝贝', value: 'on_sale' },
			{ label: '仓库中的宝贝', value: 'in_stock' }
		],
		goodsTypeOption: [
			{ label: '无', value: '0' },
			{ label: '会员折扣宝贝', value: '1' },
			{ label: '公益宝贝', value: '2' },
			{ label: '组合商品', value: '3' },
			{ label: '海外商品', value: '4' }
		],
		auditStatusOption: [
			{ label: '无', value: '0' },
			{ label: '审核中', value: '1' },
			{ label: '审核通过', value: '2' },
			{ label: '审核失败', value: '3' }
		],
		offShelfOption: [
			{ label: '全部下架', value: '0' },
			{ label: '售完下架', value: '1' },
			{ label: '我下架的', value: '2' },
			{ label: '即将开始', value: '3' },
			{ label: '从未上架', value: '4' }
		],
		code: '编码'
	},
	order: {
		tips1: '严禁一切对平台商业信息/他人信息的爬取行为（包括但不限于利用第三方或自有工具，自动记录、未经授权违规获取平台商业信息/他人信息的行为）。如发现前述违规行为，我们将按照《关于不当获取使用信息实施细则》的规定，根据行为的情节及严重程度，对用户的违规行为进行处置。',
		statusOption: [
			{ label: '全部', value: '' },
			{ label: '等待买家付款', value: 'pending' },
			{ label: '买家已付款', value: 'accepted' },
			{ label: '卖家已发货', value: 'shipped' },
			{ label: '交易成功', value: 'finished' },
			{ label: '交易关闭', value: 'canceled' },
			// { label: '待付款和待发货订单', value: '' },
			// { label: '未完成的订单', value: '' },
			// { label: '退款中的订单', value: 'refund_pay' },
			// { label: '定金已付', value: '' },
			// { label: '异常订单', value: '' }
		],
		evaluationOption: [
			{ label: '全部', value: '' },
			{ label: '需我评价', value: '1' },
			{ label: '我已评价', value: '2' },
			{ label: '对方已评', value: '3' },
			{ label: '双方已评', value: '4' }
		],
		shippingOption: [
			{ label: '全部', value: '' },
			{ label: '货到付款', value: '1' },
			{ label: '卖家物流', value: '2' }
		],
		afterSaleOption: [
			{ label: '全部', value: '' },
			{ label: '买家投诉', value: '1' },
			{ label: '我已投诉', value: '2' },
			{ label: '退款中', value: '3' }
		],
		pageNav: [
			{ label: '近三个月订单', value: 'recent' },
			{ label: '等待买家付款', value: 'pending' },
			{ label: '等待发货', value: 'accepted' },
			{ label: '已发货', value: 'shipped' },
			// { label: '退款中', value: 'refund_pay' },
			// { label: '需要评价', value: 'evaluate' },
			{ label: '成功的订单', value: 'finished' },
			{ label: '关闭的订单', value: 'canceled' },
			{ label: '三个月前订单', value: 'history' }
		],
		number: '订单编号:',
		number2: '订单号',
		createTime: '创建时间:',
		goodsCode: '商家编码:',
		statusJson: { 0: '交易关闭', 11: '等待买家付款', 20: '买家已付款，等待卖家发货', 30: '卖家已发货', 40: '交易成功', 50: '交易维权中' },
		containFreight: '(含快递)',
		consignTips1: '1.亲爱的商家！您好，近期我们收到商家对“在线下单”发货功能的反馈，目前正在考虑对该功能进行升级改造，为了更好得给您提供服务，诚邀您参与此次',
		consignTips2: '2.您正在使用新版的发货中心。为了持续优化您的使用体验，诚邀您参与',
		consignTips3: '3.目前集运仓转运、跨境出海业务暂不支持一个订单多个包裹发货，谢谢。',
		consignTips4: '1.亲爱的商家，感谢您使用新版的发货中心。',
		consignTips5: '2.目前集运仓转运、跨境出海业务暂不支持一个订单多个包裹发货，谢谢。',
		consignText1: '类型',
		consignText2: '预警',
		consignText3: '小时未发货',
		consignNav: [
			{ label: '待发货', value: 'accepted' },
			{ label: '已发货', value: 'shipped' }
		],
		consignTypeOption: [
			{ label: '全部', value: '' },
			{ label: '虚拟订单', value: 'fictitious' },
			{ label: '实物订单', value: 'material' }
		],
		sourceOption: [
			{ label: '全部', value: '1' },
			{ label: '外部导入', value: '2' }
		],
		deliveryOption: [
			{ label: '无需物流', value: '1' },
			{ label: '自己联系物流', value: '2' },
			// { label: '在线下单', value: '3' }
			// { label: '官方寄件', value: '4' }
			// { label: '其它', value: '5' }
		],
		shipping: '发货',
		shippingTime: '发货时间',
		payTime: '支付时间',
		payment: '已支付',
		unShippingType: '发货方式：无需物流',
		shopDelivery: '门店配送',
		dialogAddress1: '交易订单编号',
		dialogAddress2: '原收货地址',
		dialogAddress3: '现收货地址',
		consignee: '收货人',
		receivingTel: '收货电话',
		receivingAddress: '收货地址',
		regionJson: [ '省/地区', '市/地区', '区/街道', '街道' ],
		consignPane1: '1. 确认订单信息并填写物流单号',
		consignPane2: '2.确认发货/退货信息',
		consignPane3: '3.选择发货方式',
		consignTips6: '现已支持一个订单下多个商品拆开分多次发货',
		consignTips7: '请正确填写发货/退货地址，以避免因地址填写不准确导致货物无法退回等风险。',
		buyerConsign: '买家收货信息',
		addLogisticsNo: '添加物流单号',
		myShipping: '我的发货信息',
		myReturnInfo: '我的退货信息',
		shippingInfo: '发货信息',
		returnInfo: '退货信息',
		logisticsOption: [
			{ label: '自己联系物流', value: '2' },
			// { label: '在线下单', value: '3' },
			{ label: '无需物流', value: '1' }
		],
		consignTips8: '支持一单关联多个运单，输入多个运单号，以逗号分隔，示例：10000001,100000(逗号用英文“,”)',
		consignTips9: '销售商品为实物商品时，请谨慎选择无需物流方式发货，以避免因无物流数据产生消费者咨询/订单退款/平台处罚等风险。',
		consignTips10: '如果该物品无需物流运送（如虚拟物品），可直接点击下方确认并发货',
		logisTips1: '1.亲爱的商家，感谢您使用新版的发货中心。',
		logisTips2: '2.目前集运仓转运、跨境出海业务暂不支持一个订单多个包裹发货，谢谢。',
		logisNo: '物流单号:',
		logisStatus: '物流动态',
		noLogisStatus: '无物流动态',
		packageInfo: '包裹信息',
		unLogistics: '无需物流',
		unLogisticsWaybillNo: '无需物流发货，无运单号',
		refundTimeOption: [
			{ label: '最近申请', value: '1' },
			{ label: '历史退款', value: '2' }
		],
		refundNav: [
			{ label: '全部订单', value: '0' },
			{ label: '仅退款(未发货)', value: '1' },
			{ label: '仅退款(已发货)', value: '2' },
			{ label: '退货(已发货)', value: '3' }
		],
		refundNumber: '退款编号',
		stepsOption: [ '买家下单', '买家付款', '发货', '买家确认收货', '评价' ],
		infoTitle: '订单信息',
		tradeInfo: '交易信息',
		outTradeSn: '交易流水号:',
		paymentTime: '付款时间:',
		dealTime: '成交时间:',
		buyerInfo: '买家信息',
		nickname: '昵称:',
		location: '所在地区:',
		telephone: '联系电话:',
		email: '邮件:',
		logisticsInfo: '物流信息',
		receiveAddress: '收货地址:',
		shippingType: '运送方式:',
		shippingName: '物流公司名称:',
		logisticsCode: '运单号:',
		reasonReason: '取消原因',
		closeTips1: '请您在与买家达成一致的前提下，使用关闭交易这个功能呦!',
		closeTips2: '温馨提示',
		closeTips3: '为提升买家购物体验，您可以赠送买家店铺优惠券;',
		closeTips4: '拍下后减库存的商品，在关闭交易后，系统会自动恢复商品库存，但不会影响已下架商品状态。'
	},
	refund: {
		title: '退款详情',
		subtitle1: '交易信息',
		subtitle2: '退款信息',
		subtitle3: '商品信息',
		buyerName: '买家',
		orderSn: '订单编号',
		payTime: '成交时间',
		goodsPrice: '单价',
		postage: '邮费',
		goodsTotal: '商品总价',
		orderTotal: '订单总价',
		number: '退款编号',
		price: '退款金额',
		reason: '原因',
		require: '要求',
		explain: '说明',
		pending: '请处理退款申请',
		'step1-1': '买家申请仅退款',
		'step1-2': '买家申请退货退款',
		'step2-1': '卖家处理退款申请',
		'step2-2': '卖家处理退货申请',
		step3: '买家退货',
		'step4-1': '退款完毕',
		'step4-2': '退货完毕',
		text1: '请及时联系买家协商退款事宜',
		tips1: ['如果您同意，将直接退款给买家','如果您逾期未处理，系统将自动退款给买家'],
		text2: '请及时联系买家协商退货事宜',
		'tips2-1': '收到买家退货时，请及时验货',
		'tips2-2': '为保障顺利退货，买家申请的退款金额',
		'tips2-3': '暂做冻结',
		'tips2-4': ['该笔冻结款项在您同意退款后直接推给买家','如果买家在7天内未发货，钱款会自动解冻','卖家同意退货并提供退货地址后，买家需在7天内于系统上填写退货单号，超时未提供，退货流程将关闭处理'],
		'tips2-5': '买家已退货，收到买家退货时，请验收后同意退款',
		'tips2-6': ['如果您同意，请点击“同意退货”，将正确退货地址给买家','如果您逾期未处理，视作同意买家申请。系统会自动将当前交易的退货地址发给买家'],
		'tips2-7': ['如果拒绝退款，买家可以申请客服介入处理','如果您逾期未处理，将自动退款给买家'],
		'tips2-8': '同意退款后',
		'tips2-9': '元将立即返还买家账户',
		statusText1: '退款成功时间',
		statusText2: '退款金额',
		statusText3: '完结时间',
		statusTips3: '因买家取消退款申请，退款已关闭，交易将正常进行',
		history: '协商历史'
	},
	market: {
		title: '工具列表',
		subtitle: '店铺引流',
		subtitle2: '提升转化率',
		subtitle3: '提升客单价',
		shopCoupon: '店铺优惠券',
		shopCouponTip: '店铺优惠券',
		goodsCoupon: '商品优惠券',
		goodsCouponTip: '圈品可用',
		barTitle: '常见问题',
		singletreasure: '单品宝',
		singletreasureTip: '单品转化利器',
		combomeal: '搭配宝',
		combomealTip: '搭配提升连带购买'
	},
	campaign: {
		title: '活动报名',
		shopSignUp: '商家报名',
		shopSignUpTime: '商家报名时间',
		shopSignUpNotStarted: '商家报名未开始',
		shopSignUpProgress: '商家报名进行中',
		shopSignUpEnd: '商家报名已截止',
		itemSignUp: '商品报名',
		itemSignUpTime: '商品报名时间',
		itemSignUpNotStarted: '商品报名未开始',
		itemSignUpProgress: '商品报名进行中',
		itemSignUpEnd: '商品报名已截止',
		activityTime: '活动时间',
		activityWarmUp: '活动预热',
		warmUpText1: '预热',
		warmUpText2: '天, 售卖',
		warmUpText3: '天, 具体以报名后排期为准',
		activitySales: '活动售卖',
		activityEnd: '活动结束',
		end: '截止',
		accordWith: '您符合活动规则',
		introduce: '活动介绍',
		signUpTime: '报名时间',
		notStarted: '未开始',
		inProgress: '进行中',
		expired: '已截止',
		calendar: '大促日历',
		rhythm: '活动节奏',
		play: '玩法',
		resources: '资源',
		all: '全部活动',
		hasSignUp: '已报名',
		tipsText1: '以正式活动信息为准',
		tipsText2: '当前月份暂无活动上线，请持续关注',
		tipsText3: '活动报名未开始，敬请期待',
		tipsText4: '活动报名已结束，请点击报名其他活动',
		tipsText5: '天后报名截止',
		dialogText1: '已选择商品',
		dialogTips1: '当前页面共找到符合条件的商品',
		dialogTips2: '个，不符合条件的商品',
		dialogTips3: '个，请翻页继续查看。',
		marketId: '营销ID',
		allStock: '全部库存',
		signUpTips: '商品维度(要求对报名的商品设置相同的活动价，活动库存)',
		stockTips: '部分库存-商品维度(所有报名的sku共用活动库存，不区分每个suk报名库存数)',
		stockCurr: '当前库存量',
		limitTips1: '(数量限制： 1-9999件)',
		limitTips2: '每个会员ID累计最大可购买的数量',
		tags: '标签',
		name: '名称',
		signUp: '报名',
		activity: '活动',
		activityPrice: '活动价',
		activityInProgress: '活动中',
		draft: '草稿',
		warmUpTime: '预热时间',
		salesTime: '售卖时间',
		unapproved: '审核不通过',
		approved: '审核通过',
		signText1: '商品报名未开始，请耐心等待~',
		signText2: '商品报名中，去报名~',
		signText3: '活动预热中，更多详情可到对应活动专场查看~',
		signText4: '活动开卖中，更多详情可到对应活动专场查看',
		signText5: '本场活动已结束，欢迎您继续报名参加其他活动'
	},
	coupon: {
		title: '自定义新建',
		shop: '店铺优惠券',
		goods: '商品优惠券',
		statusOption: [
			{ label: '全部状态', value: '0' },
			{ label: '领取中', value: '1' },
			{ label: '已结束', value: '2' },
			{ label: '即将到期', value: '3' }
		],
		unlimited: '不限',
		paneTitle1: '推广渠道',
		promoteOption: [
			{ label: '全网自动推广', value: 0 },
			{ label: '官方渠道推广-客户关系管理', value: 1 },
			// { label: '自有渠道推广', value: '3' }
		],
		paneTitle2: '基本信息',
		'paneText2-1': '当商品预计到手价低于',
		'paneText2-2': '折时进行提醒',
		activityTargetOption: [
			{ label: '日常销售', value: '0' },
			{ label: '新品促销', value: '1' },
			{ label: '尾货清仓', value: '2' },
			{ label: '活动促销', value: '3' }
		],
		dialogGoodsNav: [
			{ label: '选择商品', value: '0' },
			{ label: '已选商品', value: '1' }
		],
		shopCateOption: [
			{ label: '全部类目', value: '0' },
			{ label: '其他', value: '1' }
		],
		goodsCode: '商品编号',
		paneTitle3: '面额信息',
		paneTitle4: '面额信息-面额',
		paneTips1: '最长可提前60天创建，有效期不能超过60天',
		paneTips2: '仅用于风险提示。当活动覆盖商品预测到手价≤所填折扣时进行提醒。折扣=预测到手价/单品优惠价。',
		paneTips3: '营销目标用于商品低价预警的功能判断',
		paneTips4: '一张商品优惠券最多可以选择100个指定商品。优惠券一旦创建，指定商品只能增加，不能删除。',
		paneTips5: '请输入整数金额，面额不得超过5000元',
		paneTips6: '优惠券创建后，发行量只能增加不能减少，请谨慎设置。',
		infoTitle: '优惠券详情',
		fixedUseTime: '固定使用时间',
		share: '裂变优惠券',
		shareText: '用户裂变神器',
		sharers: '分享者',
		sharedBy: '被分享者',
		channelType1: '通用',
		'channelType1-1': '设置后可在商品详情页、直播间，普通优惠券领券后页面、店铺宝活动页面、店铺装修模块中自动展示，也可直接一键置顶到店铺首页！拉回的流量默认回到店铺。',
		channelType2: '自主推广',
		'channelType2-1': '设置后不会自动透出，可用券链接用于定向渠道（如店铺首页，商详页，客服首问，站外等）传播。拉回的流量默认回到店铺。',
		followShop: '需要（关注店铺券的要求会不一样）',
		sharersCoupon: '分享者优惠券',
		'paneTips7-1': '折扣力度比',
		'paneTips7-2': '折优惠。',
		'paneTips8-1': '请输入正整数金额，面额不能超过5000元',
		'paneTips8-2': '，不小于',
		paneTips9: '（无门槛）',
		sharedByCoupon: '被分享者优惠券',
		paneTips10: '根据分享者时间自动生成',
		paneTips11: '被分享者券发行量大于等于分享者券发行量*分享人数*1.5',
		paneTips12: '请先输入分享者券发行量',
		paneTips13: '发行量只能增加不能减少'
	},
	combomeal: {
		title: '搭配宝',
		tips: '搭配宝是为卖家研发的全新商品搭配工具，是将几种商品组合在一起设置成套餐来销售，通过促销套餐可以让买家一次性购买更多的商品',
		packageNumber: '套餐数',
		packageGoods: '套餐商品数',
		packageManage: '套餐管理',
		packageGoodsManage: '套餐商品管理',
		overview: '数据概览',
		stepsOption: ['选择商品','设置套餐','设置优惠','完成并投放'],
		mainGoodsPic: '主商图',
		mainGoods: '主商品',
		subGoods: '搭配商品',
		dialogType: { 1: '选择主商品', 2: '选择搭配商品' },
		nextPackage: '下一步，设置套餐信息',
		nextGoods: '下一步，设置商品信息',
		price: '搭配价',
		previewTips: '上图仅做样式展示，实际内容可在活动创建后扫码查看!',
		packagePic: '套餐图',
		packagePicTips: '图片尺寸750*750px(宽*高)。建议：最好上传png格式的透明底色的图片。',
		packageTips: '套餐封面图：建议将套餐内商品组合拼接成一张图，并确保为白底；请勿添加价格及促销文案；提供部分行业的模板参考，其他行业可根据实际情况微调。',
		freight: '卖家承担运费',
		skuDiscount: '单品折扣（原搭配价）',
		allCate: '全部类目',
		other: '其它',
		couponInfo: '优惠信息-活动',
		duration: '活动持续',
		overdue: '优惠已经过期',
		preheat: '活动预热',
		advance: '提前',
		number: '搭配数量',
		setSkuPrice: '设置搭配价格',
		batchSet: '批量设置',
		successTips: '套餐设置成功，可以在左侧预览也可以手机扫码预览',
		couponAfoot: '优惠进行中',
		couponEnd: '优惠已结束',
		overviewTitle: '工具效果总览',
		payOrderText: '支付父订单数',
		payBuyerText: '支付买家数',
		payAmountTips1: '买家拍下后通过支付宝支付给您的金额。',
		payAmountTips2: '未剔除事后退款金额，预售阶段付款在付清当天才计入内，货到付款订单确认收货时计入内。所有终端的支付金额为PC端支付金额和无线端支付金额之和。',
		payAmountTips3: '支付渠道不论是电脑上还是手机上，拍下为电脑上，就将后续的支付金额计入PC端；拍下为手机或Pad上，就将后续的支付金额计入无线端。',
		splitDefine: '分端定义',
		jointRateText: '连带率',
		jointRateTips1: '统计周期内，支付子订单数/支付主订单数的值，即每个订单平均购买SKU商品数。',
		jointRateTips2: '如果连带率过高',
		jointRateTips3: '表示买家每次付款购买的商品数量提升，也会带动客单价的提升。建议继续保持，关注优秀同行表现。',
		jointRateTips4: '如果连带率过低',
		jointRateTips5: '代表买家每次付款购买的商品数量减少了。建议设置关联套餐的搭配。',
		payNumberText: '支付件数',
		customerPriceText: '客单价',
		perCapitaPayNumText: '人均支付件数',
		activityEffect: '活动效果',
		yesterdayPayOrder: '昨日支付父订单数',
		yesterdayPaynum: '昨日支付件数',
		yesterdayPayPrice: '昨日支付金额',
		unitPrice: '单品价',
		goodsCode: '商品编码',
		babyStatus: '宝贝状态',
		package: '套餐'
	},
	singletreasure: {
		title: '单品宝',
		tips: '单品宝是原限时打折的升级工具。最基础的促销玩法，直接表现在划线价上',
		stepsOption: ['活动设置','选择活动商品','设置商品优惠','完成'],
		setGoodsInfoText: '设置商品优惠',
		discount1: '打',
		discount2: '折',
		reduce: '减',
		salePrice: '促销价',
		errorText1: '请输入0.01~9.99之间的值，最多保留两位小数',
		errorText2: '优惠后价格低于0.01元',
		errorText3: '价格应大于0小于',
		errorText4: '，最多保留两位小数',
		errorText5: '最终优惠力度应高于店铺最低折扣：7.00',
		errorText6: '请设置优惠',
		noLimit: '不限购',
		tabText1: '活动管理',
		tabText2: 'SKU级活动商品',
		tabText3: '商品级活动商品',
		tabText4: '数据效果',
		activityNameTips: '活动名称仅用于商家自己管理活动，不会显示给消费者',
		discountLevelTips: '活动创建后，优惠级别将无法修改！',
		discountTypeTips: '活动创建后，优惠方式将无法修改！',
		attendedTips1: '已经参加当前的活动',
		attendedTips2: '该时间内，已经参加了其它活动，活动ID：',
		successText: '好的活动是需要宣传的，快去推广你的活动吧！！！',
		restartTips: '活动时间已过期，请重新设置活动时间并提交重启'
	},
	logistics: {
		title: '【物流工具】',
		navItem1: '物流基础工具',
		paneTitle1: '运费模板',
		paneTitle2: '地址库',
		paneTitle3: '运单模板',
		addressTips1: '电话号码、手机号码选填一项，备注和公司名称为可填项，其余均为必填项',
		telephone: '区号-电话',
		printList: [
			{label: '订单编号',value: 1},{label: '发件人姓名',value: 2},
			{label: '发件人电话',value: 3},{label: '发件人公司',value: 4},
			{label: '发件人地址',value: 5},{label: '发件人邮编',value: 6},
			{label: '收件人姓名',value: 7},{label: '收件人电话',value: 8},
			{label: '收件人地址',value: 9},{label: '收件人邮编',value: 10},
			{label: '代收金额',value: 11},{label: '备 注',value: 12},
			{label: '货到付款物流编号',value: 13}
		],
		freeOption: [
			{ label: '自定义运费', value: '0' },
			{ label: '包邮', value: '1' }
		],
		pricingOption: [
			{ label: '按件数', value: '1' },
			{ label: '按重量', value: '2' },
			{ label: '按体积', value: '3' }
		],
		pricingJson: [
			{ unit: '件', name: '件数' },
			{ unit: 'kg', name: '重量' },
			{ unit: 'm³', name: '体积' }
		],
		initialFilter: ['首件(个)','首重(kg)','首体积(m³)'],
		renewalFilter: ['续件(个)','续重(kg)','续体积(m³)'],
		freightTips1: '除指定地区外，其余地区的运费采用“默认运费”',
		freightText1: '默认运费',
		'freightText2-1': '内',
		'freightText2-2': '元，每增加',
		'freightText2-3': '，增加运费',
		'freightText2-4': '元',
		'freightText3-1': '在',
		'freightText3-2': '包邮',
		'freightText4-1': '满',
		'freightText4-2': '元包邮',
		'freightText5-1': '在',
		'freightText5-2': '内，',
		'freightText5-3': '元以上包邮',
		amount: '金额',
		areaOption: ['大陆', '港澳台', '海外'],
		freightLastTime: '最后编辑时间',
		china: '中国'
	},
	article: {
		title: '沉浸式学习',
		subtitle: '由浅入深，带您玩转电商直播',
		updateTime: '更新时间',
		recommend: '猜你想看',
		editor: '注：从WPS复制带有图片的内容，无法成功粘帖图片，从office复制是可以的。'
	},
	comment: {
		writeContent: '此用户没有填写评价。',
		firstTime: '初次评价',
		sellerReply: '卖家回复',
		buyerNotComment: '买家未评',
		defaultText: '天后默认好评'
	},
	customer: {
		coreDataTitle: '客户运营核心数据',
		coreDataTips: '反应店铺人群运营关系增长和成交变化的核心数据',
		totalConsume: '总消费者数',
		tips1: '近365天无店铺支付且近30天有店铺访问，或近30天支付一次且365天内首次支付的买家（去重）',
		tips2: '近365天有过店铺支付的消费者数（去除首次支付的新客）',
		tips3: '关注店铺或订阅的消费者数（去重）',
		tips4: '指对人群标题、标签等信息的修改时间，和人群人数无关',
		tips5: '指该人群的用户总量，人数会根据创建条件中的数据规则每日更新（非实时）',
		nodata1: '您还没有',
		nodata2: '，请尽快提升',
		floatSum: '总人数浮动',
		agoDay7: '较7日前',
		flat: '持平',
		yesterdayAdd: '昨日新增',
		yesterdayAddTips: '反应周期内运营策略带来店铺价值用户增长的规模',
		ratio: '日环比',
		addText1: '新增',
		addText2: '变化浮动',
		touchTitle: '触达转化',
		touchTips: '反应目标用户从触达到贡献进店转化的数据',
		click: '点击',
		deal: '成交',
		dealAmount: '成交金额浮动',
		crowdTitle: '人群管理',
		crowdTips: '人群可直接用于策略投放，如无适用，可新建个性化人群再投放应用。',
		createCrowd: '创建人群',
		crowdText1: '人群搜索',
		crowdText2: '共',
		crowdText3: '个细分人群',
		crowdText4: '人群圈选',
		crowdText5: '点击右侧标签即可添加为选中区域的人群特征',
		crowdText6: '取同时具备以下特征的消费者',
		crowdText7: '个商品',
		crowdText8: '请设置该标签属性',
		crowdText9: '点击你需要的标签，开始圈选吧～',
		crowdText10: '人群标签分类',
		crowdText11: '共搜索到',
		crowdText12: '个结果',
		crowdText13: '本店商品满',
		crowdText14: '可用',
		crowdText15: '未绑定人群',
		crowdText16: '请添加999个以内的宝贝 （已选',
		operateTitle: '自定义人群运营',
		operateTip: '发挥更具创意的营销计划，做好精细化运营，让触达到消费者的信息恰好是其想要的',
		operatSceneTitle: '场景营销',
		operatSceneTip: '平台推荐的机会人群和您自定义的人群运营',
		operatSceneText1: '今日机会人群',
		operatSceneText2: '预计转化',
		operatSceneText3: '我收藏的人群',
		operatSceneText4: '预计促活',
		operatSceneText5: '查看更多收藏人群',
		operatSceneText6: '个人群',
		operatSceneText7: '基本信息设置',
		operatSceneText8: '站内外推广',
		operatSceneText9: '设置运营标题',
		operatSceneText10: '提示：策略标题不会展示给您的外部客户，仅在后台内部可见',
		operatSceneText11: '选择运营人群',
		operatSceneText12: '运营人群',
		operatSceneText13: '人群规模',
		operatSceneText14: '店铺券',
		operatSceneText15: '本店商品满',
		operatSceneText16: '可用',
		operatSceneText17: '推送-店铺首页',
		operatSceneText18: '您发布的自定义人群优惠券，将会针对自定义人群在店铺首页等场景透出',
		operatSceneText19: '物料设置',
		operatSceneText20: '智能海报',
		operatSceneText21: '发布自定义人群权益，将自动在店铺首页以海报券的形式推送给您的自定义人群',
		operatSceneText22: '使用活动中配置的优惠券链接',
		operatSceneText23: '自定义链接',
		regularTitle: '老客复购',
		regularTips: '近365天有过店铺支付的消费者数(去除首次支付的新客)',
		compareDay: '较前日',
		regularText1: '核心数据',
		'regularText2-1': '新增老客人数',
		'regularText2-2': '当前时间下新增的老客（最近365天内成功交易的客户且剔除新客）人数，其中高于同行同层比率使用近7天数据',
		'regularText3-1': '老客进店率',
		'regularText3-2': '当前时间下老客进店人数占当天老客总人数比率，其中高于同行同层比率使用近7天数据',
		'regularText4-1': '老客复购率',
		'regularText4-2': '当前时间下老客支付人数占当天老客总人数比率，其中高于同行同层比率使用近7天数据',
		'regularText5-1': '老客成交金额',
		'regularText5-2': '当前时间下老客人群产生的成交金额数',
		'regularText6-1': '老客成交金额走势',
		'regularText6-2': '当前时间下老客的成交金额变化趋势',
		regularText7: '您的老客的进店率有待提高，复购率有待提高，请继续加油',
		'regularText8-1': '老客',
		'regularText8-2': '成交贡献拆解(最近7天)',
		regularText9: '智能运营',
		regularText10: '使用智能运营策略，预计会在7天内带来9老客转化机会',
		regularText11: '向您的老客用户设置一份专属的权益优惠',
		regularText12: '行业平均缩减60%的优惠券预算，同时提示10%的转化效率',
		regularStep1: '智能推荐人群',
		regularStep2: '智能推荐商品',
		regularStep3: '智能推荐优惠券',
		regularStep4: '渠道',
		regularInfo1: '向你的老客设置一份促进复购的专属权益',
		regularInfo2: '算法推荐个性化商品',
		'regularInfo3-1': '我们为当前人群推荐了',
		'regularInfo3-2': '个特定商品，投放时将会根据人群喜好做个性化推荐',
		regularInfo4: '个人付款',
		regularInfo5: '大数据推荐优惠',
		regularInfo6: '去补充符合条件的优惠券',
		regularInfo7: '当前库存',
		regularInfo8: '建议库存',
		regularInfo9: '差额',
		regularInfo10: '您当前暂无可用优惠券，请尽快设置',
		regularInfo11: '算法推荐推广渠道',
		regularInfo12: '根据当前人群和场景特性，我们为您优先推荐了以下通道',
		regularInfo13: '发布老客权益，将自动店铺首页以海报券的形式推送给您的老客',
		regularInfo14: '店铺首页推送',
		regularInfo15: '发布老客权益，将自动在微商品详情页以券的形式推送给您的老客',
		strategyTitle: '运营策略效果数据',
		strategyText1: '个触点',
		strategyText2: '定向海报',
		strategyText3: '店铺人群模块',
		strategyText4: '全盘',
		strategyText5: '运营效果',
		strategyText6: '统计时间',
		strategyText7: '统计维度',
		strategyText8: '人群预估人数',
		strategyText9: '指该人群的用户总量，人数会根据创建条件中的数据规则每日更新。',
		strategyText10: '注意：短信、专属客服等主动消息触达的，被发消息的为执行消息发送策略那天的人群。因不同人群的数据产出时间不一样，可能造成人群规模数据不完全准确。',
		strategyText11: '触达用户数',
		strategyText12: '根据触点不同表示为：Feeds曝光人数、短信成功发送人数、店铺人群模块曝光人数、商品详情人群模块曝光人数、专属消息成功发送人数、店铺个性化首页曝光人数等。',
		strategyText13: '另：主动消息触达（如短信、专属消息）类的，只有消息发送当天有触达用户数，因此会出现某天引导进店访客数大于触达用户数的情况。',
		strategyText14: '当前策略引导效果',
		strategyText15: '对策略执行链路进行精准的直接引导统计，严格基于消费者对营销推送内容点击之后进店并完成下单的买家进行精准统计，更好的表现营销策略的第一直接效果。',
		strategyText16: '注意此数值并不完全代表策略运营效果，侧重于营销策略的直接引导转化。如：触达后多次跳转、曝光后引起的下单以及其他品宣和间接效果均不会统计。',
		strategyText17: '另注意，短信营销中必须含有短链，且为短信模版中的“短链变量”转化而成，否则不统计直接引导效果。',
		strategyText18: '引导',
		strategyText19: '进店访客数',
		strategyText20: '收藏人数',
		strategyText21: '加购人数',
		strategyText22: '下单买家数',
		strategyText23: '下单金额',
		strategyText24: '支付买家数',
		strategyText25: '支付金额',
		strategyText26: '运营整体效果'
	},
	subscribe: {
		title: '订阅',
		toolTitle: '发布工具',
		toolText1: '多品上新',
		toolText2: '图文搭配',
		toolText3: '图文评测',
		toolText4: '清单',
		toolText5: '店铺派样',
		toolText6: '整体数据概览',
		toolText7: '创作种草内容 介绍商品卖点',
		'toolText8-1': '内容曝光人数',
		'toolText8-2': '订阅中内容曝光的人数',
		'toolText9-1': '内容点击人数',
		'toolText9-2': '订阅中内容点击的人数',
		'toolText10-1': '累计粉丝人数',
		'toolText10-2': '全店当前粉丝数，不含已取关的粉丝',
		'toolText11-1': '订阅活跃粉丝数占比',
		'toolText11-2': '订阅活跃粉丝数',
		effectTitle: '内容效果',
		'effectText1-1': '内容曝光次数',
		'effectText1-2': '卡片的曝光次数',
		'effectText2-1': '内容曝光人数',
		'effectText2-2': '卡片的曝光人数',
		'effectText3-1': '进宝贝次数',
		'effectText3-2': '通过卡片及详情页引流进入宝贝的次数',
		'effectText4-1': '进宝贝人数',
		'effectText4-2': '通过卡片及详情页引流进入宝贝的人数',
		'effectText5-1': '进店次数',
		'effectText5-2': '通过卡片及详情页引流进入店铺的次数',
		'effectText6-1': '进店人数',
		'effectText6-2': '通过卡片及详情页引流进入店铺的人数',
		trendTitle: '内容趋势',
		trendText1: '每次最多选择 5 个指标',
		generatorTips1: '有明确的组货逻辑。比如：上新，按产品不同系列组货、跨品类店铺按不同的系列组货；清单类内容，按趋势/风格话题进行组货',
		generatorTips2: '表达搭配技巧，传递美感、风格、趋势等。',
		generatorTips3: '方式1——人格化表达，真人上身试穿试戴，单套/多套穿搭，建议全身look+细节表达',
		generatorTips4: '方式2——讲解穿搭技巧、风格搭配、场景搭配（出游装、party装等）',
		generatorTips5: '具备一定专业属性，如真实的使用分享，知识传递',
		generatorText1: '品牌官方旗舰店｜1小时前发布',
		generatorText2: '请输入描述文案',
		generatorText3: '文案',
		generatorText4: '全部商品',
		generatorText5: '上新',
		generatorText6: '查看已选商品',
		generatorText7: '最多选择 9 个、最少选择 3 个商品',
		generatorText8: '请在右侧添加图片',
		generatorText9: '图片',
		generatorText10: '图，上传后可在图片上添加标签以辅助说明。图片比例为3:4时不得小于750px*1000px；图片比例为1:1时不得小于750px*750px，支持JPG或PNG格式，需小于3MB。',
		generatorText11: '您可上传2-9张商品',
		generatorText12: '用文字向粉丝描述您的商品',
		generatorText13: '报告，辅助',
		generatorText14: '图片向粉丝种草。',
		generatorText15: '点击图片添加标签',
		generatorText16: '请在左侧选择合适的位置点击添加标签，设定标签文案并关联宝贝',
		generatorText17: '有助于提升本条内容的转化哦！',
		generatorText18: '可以设置 0~3 个标签',
		generatorText19: '尚未添加任何宝贝和标签',
		generatorText20: '请选择 1 个商品',
		analysisTitle: '效果分析',
		analysisText1: '人群触达漏斗',
		'analysisText2-1': '粉丝总量',
		'analysisText2-2': '店铺粉丝总数',
		'analysisText3-1': '订阅活跃粉丝量',
		'analysisText3-2': '昨日登录订阅、店铺动态的粉丝数',
		'analysisText4-1': '实际触达粉丝量',
		'analysisText4-2': '订阅、店铺动态浏览内容的粉丝数量',
		'analysisText5-1': '订阅粉丝活跃率',
		'analysisText5-2': '活跃粉丝量/粉丝总数',
		'analysisText6-1': '订阅粉丝触达率',
		'analysisText6-2': '实际触达粉丝量/活跃粉丝量',
		overviewTitle1: '用户资产数据',
		overviewTitle2: '内容数据',
		overviewTitle3: '成交转化',
		'overviewText1-1': '订阅活跃粉丝数',
		'overviewText1-2': '昨日访问订阅和店铺动态的粉丝数',
		'overviewText2-1': '订阅活跃粉丝数占比',
		'overviewText2-2': '活跃的粉丝数/粉丝总数',
		'overviewText3-1': '内容曝光次数',
		'overviewText3-2': '所在内容卡片在订阅和店铺动态曝光的次数',
		'overviewText4-1': '内容曝光人数',
		'overviewText4-2': '所在内容卡片在订阅和店铺动态曝光的人数',
		'overviewText5-1': '引导进店次数',
		'overviewText5-2': '订阅引导进入店铺、宝贝详情、直播的次数和店铺动态引导进宝贝详情和直播的次数之和',
		'overviewText6-1': '引导进店人数',
		'overviewText6-2': '订阅引导进入店铺、宝贝详情、直播的次数和店铺动态引导进宝贝详情和直播的人数之和',
		'overviewText7-1': '引导进宝贝次数',
		'overviewText7-2': '订阅和店铺动态引导进入宝贝详情的次数',
		'overviewText8-1': '引导进宝贝人数',
		'overviewText8-2': '订阅和店铺动态引导进入宝贝详情的人数',
		'overviewText9-1': '渠道总成交金额',
		'overviewText9-2': '通过浏览卡片及详情页最终引导成交的GMV，不包含直播和通过券引导的金额',
		'commentText1-1': '总共',
		'commentText1-2': '条评论',
		commentText2: '置顶评论',
		commentText3: '全部评论',
		commentEmpty1: '还没有评论被置顶呢，快来置顶评论让更多人看到吧～',
		commentEmpty2: '该作品下方暂时还没人发表评论喔～'
	},
	header: {
		teacher: '教师管理',
		userInfo: '账号信息',
		shopInfo: '店铺信息',
		help: '帮助中心',
		goodsCate: '宝贝分类',
		material: '素材中心',
		exit: '退出',
		logout: '退出当前账号',
		index: '商城首页'
	},
	screen: {
		title: '标题',
		sales: '销量',
		price: '价格',
		shopName: '店铺名称',
		shopCate: '店铺分类',
		classBelong: '所属班级',
		useHelp: '使用帮助',
		showMaterial: '显示内容',
		goodsId: '商品ID',
		goodsTitle: '商品标题',
		goodsName: '商品名称',
		goodsCode: '商家编码',
		goodsStatus: '商品状态',
		primaryCate: '一级类目',
		offShelf: '下架状态',
		auditStatus: '审核状态',
		selected: '已选',
		orderSn: '订单号',
		orderNumber: '订单编号',
		orderStatus: '订单状态',
		orderSource: '订单来源',
		babyId: '宝贝ID',
		babyType: '宝贝类型',
		babyName: '宝贝名称',
		babyNameId: '宝贝名称/ID',
		buyerName: '买家昵称',
		evaluation: '评价状态',
		logistics: '物流服务',
		afterSale: '售后服务',
		createTime: '创建时间',
		payTime: '支付时间',
		recipientName: '收件人姓名',
		recipientMobile: '收件人手机',
		address: '地址',
		shippingType: '发货方式',
		waybillNo: '运单号',
		applyTime: '申请时间',
		latelyModify: '最近修改',
		refundAmount: '退款金额',
		refundNumber: '退款编号',
		refundTime: '退款时间',
		refundType: '退款类型',
		refundStatus: '退款状态',
		reason: '原因',
		returnLogisticsMode: '退货物流方式',
		returnLogisticsStatus: '退货物流状态',
		trustStatus: '托管状态',
		waiterIntervene: '小二介入',
		search: '搜索',
		commentTime: '评价时间',
		commentType: '评价类型',
		commentContent: '评价内容',
		campaignId: '活动ID',
		campaignName: '活动名称',
		campaignTag: '活动标签',
		campaignStatus: '活动状态',
		campaignType: '活动类型',
		campaignGoodsPrice: '商品活动价格',
		signUpType: '报名类型',
		perfectStatus: '完善状态',
		buyItNow: '店铺一口价',
		limitBuy: '每个ID限购',
		campaignSearchId: '商品ID/营销ID',
		goodsSignUpStatus: '商品报名状态',
		goodsPerfectStatus: '商品完善状态',
		signUpPerfectStatus: '报名是否完善',
		signRecordId: '报名记录ID',
		stockType: '库存类型',
		signUpStock: '报名库存',
		signUpTimeSort: '报名时间降序',
		campaignTimeSort: '活动时间降序',
		goodsCate: '商品类目',
		couponLevel: '优惠级别',
		startTime: '开始时间',
		endTime: '结束时间',
		discountPrice: '优惠价',
		typeFilter: '类型筛选',
		sort: '排序'
	},
	tableHead: {
		title: '标题',
		time: '时间',
		cate: '分类',
		cateImg: '分类图片',
		cateName: '分类名称',
		cateType: '分类类型',
		grade: '评分',
		ranking: '排名',
		score: '分数',
		scoreItem: '分项评分',
		scoreIntelligent: '智能评分',
		scoreTeacher: '教师评分',
		scoreSystem: '系统得分',
		comment: '评语',
		total: '总计',
		totalScore: '总分',
		totalGrade: '总评分',
		totalSales: '总销量',
		userName: '账号',
		userName2: '会员名',
		studentName: '学生名',
		shopName: '店铺名',
		class: '班级',
		handle: '操作',
		move: '移动',
		defaultOpen: '默认展开',
		createTime: '创建时间',
		name: '名称',
		money: '金额',
		price: '价格',
		validity: '有效期',
		useLimits: '使用限制',
		goods: '商品',
		goodsName: '商品名称',
		goodsAttr: '商品属性',
		goodsPrice: '商品价格',
		goodsInfo: '宝贝信息',
		goodsType: '商品类型',
		goodsTotal: '商品总价',
		goodsAuditInfo: '商品提报信息',
		stock: '库存',
		sales: '销量',
		introduce: '文本提报',
		shopIntro: '店铺介绍',
		shopWideGraph: '店铺宽图',
		shopLongGraph: '店铺长图',
		shopEffect: '店铺印象',
		pageName: '页面名称',
		navName: '页面名称/投放人群',
		type: '类型',
		updateTime: '更新时间',
		status: '状态',
		frontDisplay: '前台展示',
		baby: '宝贝',
		babyDescribe: '宝贝描述',
		babyAttribute: '宝贝属性',
		unitPrice: '单价',
		number: '数量',
		afterSales: '售后',
		payStatus: '交易状态',
		realPayment: '实收款',
		evaluate: '评价',
		address: '地址',
		addressInfo: '详情地址',
		payAmount: '交易金额',
		refundAmount: '退款金额',
		refundStatus: '退款状态',
		latelyApplyTime: '最近申请时间',
		reason: '原因',
		returnLogistics: '退货物流',
		returnAddress: '退货地址',
		shippingLogistics: '发货物流',
		shippingAddess: '发货地址',
		channel: '名称/渠道',
		denomination: '面额',
		threshold: '门槛',
		useTime: '使用时间',
		limitedCollar: '限领',
		circulation: '发行量',
		received: '已领取',
		contacts: '联系人',
		location: '所在地区',
		postCode: '邮政编码',
		telephone: '电话',
		companyName: '公司名称',
		phone: '手机',
		templateName: '模板名称',
		deliveryCompany: '快递公司',
		isDefault: '是否默认',
		shipTo: '运送到',
		initial: '首',
		initialFee: '首费(元)',
		renewal: '续',
		renewalFee: '续费(元)',
		shippingMode: '运送方式',
		freight: '运费(元)',
		firstPiece: '首件(个)',
		addPiece: '续件(个)',
		logisticsFilter: { express: '快递', ems: 'EMS', surface: '平邮' },
		colorCate: '颜色分类图',
		describe: '描述',
		service: '服务',
		discount: '优惠',
		discountPrice: '优惠价格',
		scoreStatus: '计分状态',
		commentContent: '评价内容',
		buyerInfo: '买家信息',
		orderAmount: '订单金额',
		orderSn: '订单编号',
		onlineStock: '线上库存',
		category: '类目',
		accordWith: '是否符合条件',
		campaignPrice: '活动价格',
		campaignInfo: '活动信息',
		itemSignUpStatus: '商品报名活动状态',
		packageId: '套餐ID',
		packageName: '套餐名称',
		packageNameId: '套餐名称/ID',
		packageMainPic: '套餐主图',
		packageDesc: '套餐介绍',
		packageType: '套餐类型',
		packageStatus: '套餐状态',
		packageAttr: '套餐属性',
		packagePrice: '套餐价',
		costPrice: '原价',
		shopCombomealPrice: '同店搭配价',
		couponInfo: '优惠信息',
		couponTime: '优惠时间',
		couponStatus: '优惠状态',
		priceAfterDiscount: '优惠后价格',
		perLimit: '每人限购',
		crowd: '人群',
		crowdName: '人群包名称',
		crowdEstimate: '人群总预估人数',
		coupon: '优惠券',
		crowdCoupon: '人群优惠券',
		limitedCopies: '限领份数',
		stockNum: '库存数量',
		posterEditor: '海报编辑',
		strategyInfo: '运营策略信息',
		contactInfo: '触点信息',
		strategyTime: '策略创建时间',
		contentTitle: '内容标题',
		publishTime: '发布时间',
		role: '角色',
		couponType: '券类型',
		exfiltrateTime: '开始透出时间',
		buyNum: '购买数量',
		actualPay: '实际付款',
		countDown: '倒计时'
	},
	formItem: {
		name: '名称',
		title: '标题',
		shopId: '店铺ID',
		shopName1: '店铺名',
		shopName2: '店铺名称',
		shopTitle: '店铺标题',
		shopIntroduce: '店铺介绍',
		shopStory: '店铺故事',
		shopDesc: '店铺说明',
		shopInfo: '基础信息',
		shopLogo: '店铺标志',
		shopDomain: '店铺域名',
		decorateSwitch: '装修开关（打开即可装修）',
		moduleName: '模块名称',
		basicSetup: '基础设置',
		uploadPic: '上传图片',
		subUndertake: '二级承接页',
		link: '链接',
		jumpLink: '跳转链接',
		styleOption: '样式选择',
		vedioSize: '上传视频尺寸',
		addVedio: '添加视频',
		vedioSubpage: '视频二级页',
		moduleStyle: '模块样式',
		bannerGraph: 'Banner图',
		bannerLink: 'Banner链接',
		goodsStock: '选择商品库',
		couponAdd: '添加优惠劵',
		couponSet: '设置优惠劵数量',
		couponAmount: '优惠金额',
		account: '账号',
		mailbox: '邮箱',
		loginPassword: '登录密码',
		bindMobile: '绑定手机',
		bindIdentity: '绑定身份信息',
		mobile: '手机号',
		mobileNumber: '手机号码',
		verifyCode: '验证码',
		ownerCard: '身份证号',
		address: '详细地址',
		addresses: '联系地址',
		contacts: '联系人',
		pageName: '页面名称',
		navName: '导航名称',
		consignee: '收货人',
		receivingTel: '收货电话',
		receivingAddress: '现收货地址',
		landline: '座机电话',
		logisticsNo: '物流单号',
		shippingType: '发货方式',
		shippingTime: '发货时间',
		shippingSite: '发货地',
		shippingMode: '运送方式',
		performanceNo: '履约单号',
		packageGoods: '包裹中的商品',
		useTime: '使用时间',
		useTimeMode: '使用时间模式',
		useThreshold: '使用门槛',
		cheapRemind: '低价提醒',
		activityTarget: '活动目标',
		activityExpire: '活动到期提醒',
		expireRemind: '到期提醒',
		goodsRange: '商品范围',
		circulation: '发行量',
		limitPerPerson: '每人限领',
		denomination: '面额',
		preferentialWay: '优惠方式',
		location: '所在地区',
		street: '街道地址',
		postCode: '邮政编码',
		telephone: '电话号码',
		remark: '备注',
		companyName: '公司名称',
		templateName: '模板名称',
		deliveryCompany: '快递公司',
		selectPrint: '选择打印项',
		isFree: '是否包邮',
		pricingMode: '计价方式',
		goodsImage: '上传商品主图',
		goodsImagePc: '电脑端宝贝图片',
		goodsCate: '确认商品类目',
		goodsName: '宝贝标题',
		goodsType: '宝贝类型',
		goodsSize: '尺码',
		goodsSpecs: '宝贝销售规格',
		goodsPrice: '一口价',
		goodsCode: '商品条形码',
		goodsVideo: '主图视频',
		goodsLong: '宝贝长图',
		cateAttr: '类目属性',
		purchaseFrom: '采购地',
		shopCate: '店铺中分类',
		shopCode: '商家编码',
		colorCate: '颜色分类',
		payMode: '付款方式',
		totalQuantity: '总数量',
		stockCount: '库存计数',
		afterSale: '售后服务',
		extractionMode: '提取方式',
		videoScale: '主图视频比例',
		description: '电脑端描述',
		mobileContent: '手机端描述',
		shelvesTime: '上架时间',
		closeReason:'请选择关闭该交易的理由',
		refundsAddress: '退货地址',
		refundsExplain: '退货说明',
		refuseReason: '拒绝原因',
		refuseExplain: '拒绝说明',
		uploadVoucher: '上传凭证',
		basicCoupon: '基础优惠',
		moreCoupon: '更多优惠',
		selectCoupon: '选择优惠',
		couponType: '优惠类型',
		activitySet: '活动设置',
		mainColor: '主要颜色',
		activityFree: '活动包邮',
		shopRelation: '店铺关系',
		contentIndex: '内容指标',
		salesIndex: '导购指标',
		mentionBaby: '所有提及的宝贝',
		connectBaby: '关联宝贝',
		shareFollowShow: '分享关注店铺',
		shareNum: '分享人数',
		styleType: '款式类型'
	},
	actions: {
		search: '搜索',
		searchOrder: '搜索订单',
		clearQuery: '清除条件',
		edit: '编辑',
		editGoods: '编辑商品',
		editPage: '页面编辑',
		editPackge: '编辑套餐',
		delete: '删除',
		deletePage: '删除页面',
		deleteDenomination: '删除此面额',
		expand: '展开',
		expandSearch: '展开搜索条件',
		collapse: '收起',
		upload: '上传',
		uploadLogo: '上传logo',
		newFile: '新建文件',
		newFolder: '新建文件夹',
		newBound: '新增绑定',
		newPage: '新建页面',
		newTemplate: '新建模板',
		newFreightTpl: '新增运费模板',
		confirm: '确定',
		confirm2: '确认',
		confirmShip: '确认并发货',
		confirmCreate: '确认生成',
		confirmRefuse: '确定拒绝',
		confirmPublish: '确定发布',
		confirmSubmit: '确认提交',
		confirmOffline: '确认下线',
		confirmDelete: '确认删除',
		confirmCreate2: '确认创建',
		cancel: '取消',
		cancelHome: '取消首页',
		close: '关闭',
		'cancel&Close': '取消并关闭',
		copy: '复制',
		copyLink: '复制地址',
		copyShortChain: '复制短链',
		rename: '重命名',
		moveTo: '移动到',
		save: '保存',
		saveChange: '保存更改',
		saveSet: '保存设置',
		saveClose: '保存并关闭',
		publish: '发布',
		publishGoods: '发布商品',
		publishSite: '发布站点',
		add: '添加',
		addLink: '添加链接',
		addVideo: '添加视频',
		addGoods: '添加商品',
		addImage: '添加图片',
		addChildCate: '添加子分类',
		addManualCate: '添加手工分类',
		addAutoCate: '添加自动分类',
		addPackage: '添加包裹',
		addNewDenomination: '增加新面额',
		addAddress: '添加地址',
		addGoodsImage: '添加上传图片',
		addFreightTpl: '新建运费模板',
		addAbstract: '摘要',
		addPicture: '图片',
		addText: '文字',
		addLayout: '添加布局单元',
		addBaby: '添加宝贝',
		learnMore: '了解详情',
		select: '选择',
		selected: '已选择',
		selectModule: '选择已有模块',
		selectCoupon: '请选择优惠券',
		selectGoods: '选择商品',
		view: '查看',
		viewAll: '查看全部',
		viewGoods: '查看商品',
		viewDetails: '查看详情',
		viewStandard: '查看详细规范',
		viewAddress: '查看发货/退货信息',
		viewShop: '查看店铺',
		viewShopCate: '查看店铺分类',
		viewNorm: '查看规范',
		viewMore: '查看更多',
		viewCampaignDesc: '查看活动介绍',
		more: '更多',
		createMoreCoupon: '创建更多优惠劵',
		batchDelete: '批量删除',
		batchShelves: '批量上架',
		batchOffshelf: '批量下架',
		batchExport: '批量导出',
		batchShipping: '批量发货',
		batchMarking: '批量标记',
		batchFreightFree: '批量免运费',
		batchReturn: '批量同意退货',
		batchRefund: '批量同意退款',
		batchFill: '批量填充',
		immediateShelves: '立即上架',
		immediateOffshelf: '立即下架',
		immediateCreate: '立即创建',
		modify: '修改',
		modifyMobile: '修改手机',
		modifyPassword: '修改密码',
		modifyOrderSn: '修改单号',
		backModify: '放弃修改',
		toPerfect: '去完善',
		enterSeller: '进入卖家中心',
		enterOpenShop: '进入开店完成页',
		submit: '提交',
		submitCertificate: '提交认证',
		submitGoods: '提交宝贝信息',
		applyOpenShop: '申请开店',
		decoratePage: '装修页面',
		setHome: '设为首页',
		setNewNotice: '设置上新公告',
		setDefault: '设为默认',
		regularRelease: '定时发布',
		details: '详情',
		closePay: '关闭交易',
		shipping: '发货',
		delayReceiving: '延时收货时间',
		survey: '调研',
		questionnaire: '问卷调研',
		orderShipping: '订单发货',
		logisticsDetails: '物流详情',
		unlogisticsShipping: '无需物流发货',
		createAddress: '从地址库新建地址',
		refresh: '刷新',
		refreshTpl: '刷新模板数据',
		export: '导出Excel',
		trustSet: '托管设置',
		addressTemplates: '管理地址模板',
		createCoupon: '创建优惠券',
		end: '结束',
		riskCheck: '资损风险校验',
		back: '返回',
		backList: '返回列表',
		setFreight: '为指定地区城市设置运费',
		nextGoods: '下一步，完善商品信息',
		purity: '纯色',
		simplicity: '简约',
		replace: '替换',
		selectImage: '选择图片',
		selectVideo: '选择视频',
		importDesc: '导入电脑端描述',
		moveUp: '上移',
		moveDown: '下移',
		prevStep: '上一步',
		uploadImg: '上传图片',
		picManage: '进入图片管理',
		editBuyerAddress: '修改收货地址',
		agreeRefund: '同意退款',
		refuseApply: '拒绝申请',
		refuseRefund: '拒绝退款',
		agreeRefunds: '同意退货',
		refuseRefunds: '拒绝退货申请',
		refuseRefunds2: '已收到货，同意退款',
		refuseRefunds3: '确认收货，同意退款',
		uploadVoucher: '上传凭证',
		uploadVoucherTips10: '最多10张',
		continue: '继续',
		layoutManage: '布局管理',
		preview: '预览',
		commentReply: '评价回复',
		submitSignUp: '提交报名',
		nextStep: '下一步',
		goSignUp: '去报名',
		registerNow: '立即报名',
		perfectGoods: '完善商品',
		activityWarmUp: '点击进入预热活动专场',
		activitySales: '点击进入售卖活动专场',
		goodsSignUp: '去报名商品',
		createActivity: '创建活动',
		addCombomealGoods: '添加搭配商品',
		buyNow: '立即购买',
		savePackage: '保存套餐',
		backCombomealHome: '返回搭配宝首页',
		reset: '重置',
		restart: '重启',
		recovery: '恢复',
		suspend: '暂停',
		couponSet: '优惠设置',
		round: '取整',
		unround: '取消取整',
		erasure: '抹分',
		unerasure: '取消抹分',
		withdrawalActivity: '撤出活动',
		withdrawalPackage: '撤出套餐',
		batchWithdrawal: '批量撤出',
		createSingletreasure: '创建单品宝',
		nonPostalArea: '不包邮地区',
		saveAndContinue: '保存并继续',
		setCoupon: '设置优惠',
		modifyActivity: '修改活动',
		viewActivity: '查看活动',
		editCoupon: '编辑优惠',
		addCrowd: '新建人群圈选',
		saveCrowd: '立即保存人群',
		setCouponCrowd: '设置优惠券人群',
		addCollectCrowd: '添加更多收藏人群',
		viewAllCrowd: '查看全部人群',
		collectCrowd: '收藏已选人群',
		uncollect: '取消收藏',
		addCoupon: '新建优惠券',
		collectNow: '立即领取',
		backStep: '返回上一步',
		onekeyPromote: '一键推广',
		viewRegularNote: '查看历史运营策略',
		openNow: '立即开启',
		backCrowdList: '返回新客人群看板',
		data: '数据',
		terminate: '终止',
		createNow: '立即创作',
		query: '查询',
		viewData: '查看数据',
		offline: '下线',
		topping: '置顶',
		untopping: '取消置顶',
		commentManage: '评论管理',
		publishContent: '发布内容',
		saveDraft: '保存草稿',
		editLabel: '编辑标签',
		deleteItem: '删除此条',
		reply: '回复',
		viewAllReply: '查看全部回复',
		reselect: '重新选择',
		skip: '跳过'
	},
	options: {
		commentMenu: [
			{ label: '来自买家的评价', value: '1' },
			// { label: '待卖家评价', value: '2' },
			{ label: '待买家评价', value: '3' },
			// { label: '给买家的评价', value: '4' }
		],
		evaluateGrade: [
			{ label: '好评', value: '1' },
			{ label: '中评', value: '2' },
			{ label: '差评', value: '3' }
		],
		evaluateContent: [
			{ label: '有内容', value: '1' },
			{ label: '有图片', value: '2' }
		],
		refundType: [
			{ label: '全部', value: '' },
			{ label: '售中退款', value: '1' },
			{ label: '售后退款', value: '2' }
		],
		refundStatus: [
			{ label: '全部', value: '' },
			{ label: '进行中的订单', value: '1' },
			{ label: '退款待处理', value: '2' },
			{ label: '已拒绝退款', value: '3' },
			{ label: '待买家发货', value: '4' },
			{ label: '待商家收货', value: '5' },
			{ label: '退款关闭', value: '6' }
		],
		returnLogistics: [
			{ label: '全部', value: '0' },
			{ label: '买家联系快递寄件', value: '1' },
			{ label: '上门取件', value: '2' },
			{ label: '驿站寄件', value: '3' },
			{ label: '自提柜寄件', value: '4' }
		],
		depositStatus: [
			{ label: '全部', value: '0' },
			{ label: '未托管', value: '1' },
			{ label: '托管成功', value: '2' },
			{ label: '托管失败', value: '3' }
		],
		logisticsStatus: [
			{ label: '全部', value: '0' },
			{ label: '等待揽收', value: '1' },
			{ label: '快件已揽收', value: '2' },
			{ label: '物流走件中', value: '3' },
			{ label: '派送中', value: '4' },
			{ label: '已签收', value: '5' },
			{ label: '签收失败', value: '6' }
		],
		waiterIntervene: [
			{ label: '全部', value: '0' },
			{ label: '小二未介入', value: '1' },
			{ label: '小二已介入', value: '2' },
			{ label: '请举证处理', value: '3' }
		],
		refundSteps: ['买家申请仅退款','卖家处理退款申请','退款完毕'],
		publishStatus: ['未发布','已发布'],
		campaignStatusTags: {1:'报名',2: '报名',3: '报名',4:'预热',5:'售卖',6:'结束'},
		campaignStatusText: {1:'活动报名未开始', 2: '商品报名未开始',3: '商品报名进行中', 4: '活动预热进行中', 5: '活动售卖进行中',6:'活动售卖已截止'},
		signUp: {0:'不可报',1:'可报名'},
		goodsStatus: [
			{ label: '全部', value: '0' },
			{ label: '出售中', value: '1' },
			{ label: '仓库中', value: '2' },
			{ label: '违规中', value: '3' }
		],
		campaignStatus: [
			{ label: '全部', value: '-1' },
			{ label: '草稿', value: '0' },
			{ label: '审核通过', value: '1' },
			{ label: '审核拒绝', value: '2' },
			{ label: '预热中', value: '3' },
			{ label: '活动中', value: '4' },
			{ label: '活动结束', value: '5' }
		],
		perfectStatus: [
			{ label: '全部', value: '0' },
			{ label: '已完善', value: '1' },
			{ label: '待完善', value: '2' },
		],
		campaignType: [
			{ label: '全部', value: '0' },
			{ label: '未开始', value: '1' },
			{ label: '商家报名中', value: '2' },
			{ label: '商品报名中', value: '3' },
			{ label: '活动预热中', value: '4' },
			{ label: '活动售卖中', value: '5' },
			{ label: '已结束', value: '6' }
		],
		goodsSignUpStatus: [
			{ label: '全部', value: '-1' },
			{ label: '草稿', value: '0' },
			{ label: '审核通过', value: '1' },
			{ label: '审核拒绝', value: '2' },
			{ label: '预热中', value: '3' },
			{ label: '活动中', value: '4' },
			{ label: '活动结束', value: '5' }
		],
		packageType: [
			{ label: '自选商品套餐', value: 1, tips: '套餐中的搭配商品，消费者可有选择性的购买' },
			{ label: '固定组合套餐', value: 2, tips: '套餐所有商品打包销售，消费者需成套购买' }
		],
		packageAttr: [
			{ label: '标准套餐', value: 1 }
		],
		selectCoupon: [
			{ label: '使用优惠', value: 1 },
			{ label: '不使用优惠', value: 0 }
		],
		discountType: [
			{ label: '优惠价', value: 1 },
			{ label: '折扣', value: 2 }
		],
		combomealType: [
			{label: '全部类型', value: ''},
			{label: '自选商品套餐', value: '1'},
			{label: '固定组合套餐', value: '2'}
		],
		combomealStatus: [
			{label: '全部状态', value: ''},
			{label: '进行中', value: '1'},
			{label: '已暂停', value: '2'}
		],
		activityStatus: [
			{label: '进行中', value: 1},
			{label: '已结束', value: 2}
		],
		combomealChart: {
			'avg_pay_num': '人均支付件数',
			'customer_price': '客单价',
			'pay_buyers': '支付买家数',
			'pay_num': '支付件数',
			'pay_order': '支付订单数',
			'pay_order_rate': '连带率',
			'pay_price': '支付金额',
			'pay_sub_order': '支付子订单数',
		},
		shopCate: [
			{label: '全部', value: -1},
			{label: '未分类', value: 0}
		],
		babyStatus: [
			{label: '全部状态', value: ''},
			{label: '出售中的商品', value: 1},
			{label: '仓库中的商品', value: 2}
		],
		singletreasureStatus: [
			{label: '全部', value: ''},
			{label: '已暂停', value: '1'},
			{label: '未开始', value: '2'},
			{label: '进行中', value: '3'},
			{label: '已结束', value: '4'},
			{label: '已删除', value: '5'},
		],
		crowdType: [
			{label: '官方推荐', value: 0},
			{label: '自定义群体', value: 1}
		],
		crowdSex: [
			{label: '女', value: 1},
			{label: '男', value: 2}
		],
		crowdRange: [
			{label: '区间', value: 0},
			{label: '小于', value: 1},
			{label: '大于', value: 2}
		],
		customerCoupon: [
			{label: '待启用优惠券', value: 0},
			{label: '老客优惠券', value: 1},
			{label: '人群优惠券', value: 2}
		],
		customerType: [
			// {label: '新客', value: 1},
			{label: '老客', value: 2},
			{label: '人群', value: 3}
		],
		strategyTabs: [
			{label: '全部', value: '-1'},
			{label: '老客复购', value: '0'},
			{label: '自定义人群运营', value: '1'}
		],
		subscribeType: [
			{label: '全部', value: -1},
			{label: '多品上线', value: 0},
			{label: '图文搭配', value: 1},
			{label: '图文评测', value: 2},
			{label: '清单', value: 3}
		],
		istop: [
			{label: '全部', value: 0},
			{label: '仅显示置顶', value: 1},
		],
		goodsSearchType: [
			{label: '按商品名', value: 1},
			{label: '按商品ID', value: 2},
		],
		styleType: [
			{label: '福利款（秒杀款）', value: 1},
			{label: '捡漏款（性价比）', value: 2},
			{label: '王炸款（自留款）', value: 3},
			{label: '利润款（印象款）', value: 4},
		],
		orderSort: [
			{label: '最新下单在上', value: 'asc'},
			{label: '最新下单在下', value: 'desc'},
		]
	},
	checkbox: {
		checkAll: '全选',
		agreement: '已阅读并同意协议',
		openAgreement1: '我已阅读并同意',
		openAgreement2: '直播运营仿真实训系统子域名自助注册及使用规则',
		closeOrder: '不显示已关闭的订单',
		relatedGoods: '关联商品',
		express: '快递',
		ems: 'EMS',
		surface: '平邮',
		setPackage: '设置包邮条件',
		invoice: '提供发票',
		returnsPromise: '退换货承诺',
		logistics: '使用物流配送',
		payVoucher: '电子交易凭证',
		rookieBill: '菜鸟打单',
		buyerLeave: '含买家留言',
		skuGoods: '仅展示含有SKU的商品'
	},
	dialogTitle: {
		image: '选择图片',
		goods: '选择商品',
		coupon: '选择优惠券',
		uploadImg: '上传图片',
		newPage: '新建页面',
		newFolder: '新建文件夹',
		newTemplate: '新建模板',
		newFreightTpl: '新增运费模板',
		renameFolder: '文件夹重命名',
		folderMove: '文件夹移动到',
		moduleName: '选择模块/',
		example: '示例说明',
		addAddress: '添加新地址',
		modifyWaybillNo: '修改运单号',
		modifyAddress: '修改地址',
		editAddress: '编辑地址',
		editTemplate: '编辑模板',
		editFreightTpl: '修改运费模板',
		address: '选择地址',
		refundPending: '退货申请处理',
		refuseRefund: '拒绝退款',
		layoutManage: '布局管理',
		commentReply: '评价回复',
		optionalItems: '可选商品列表',
		restartActivity: '重启活动',
		addCollect: '添加收藏',
		collectManage: '已收藏人群管理',
		selectGoods: '商品选择',
		addBabyTag: '点击图片添加宝贝标签',
		allReply: '回复评论',
		replyCommnet: '回复评论'
	},
	pagination: {
		prevText: '上一页',
		nextText: '下一页',
		prevChapter: '上一篇',
		nextChapter: '下一篇'
	},
	placeholder: {
		default: '请输入',
		title: '请输入标题',
		instruction: '指导意见',
		shopName1: '请输入店铺名',
		shopName2: '请输入店铺名称',
		unfiltered: '未筛选',
		material: '搜索图片或文件夹',
		folderName: '请输入分组名称,长度不超过20个字',
		keyword: '输入搜索关键词',
		groupName: '请输入分组名称,长度不超过20个字',
		videoTitle: '请输入视频标题',
		selectLink: '请选择链接',
		moduleName: '请输入模块名称',
		wirelessLink: '请输入合法的无线链接',
		link: '请输入链接',
		ids: '多个ID以英文逗号或空格分隔',
		minimum: '最低',
		highest: '最高',
		shopIntro: '16-24个字,店铺介绍',
		pageName: '请输入页面名称，长度不超过25个文案',
		originDate: '起始日期',
		startDate: '开始日期',
		endDate: '结束日期',
		landline: '座机号码',
		logisticsNo: '请输入单号再选择对应物流公司',
		logisticsRemark: '选填，加赠品或拆包可在备注中说明',
		shippingRemark: '发货备注,仅自己可见',
		orderGoods: '请选择订单中商品',
		orderSn: '请输入单号',
		buyerName: '请填写买家昵称',
		returnWaybillNo: '请填写退货运单号',
		orderNumber: '请填写订单编号',
		refundNumber: '请填写退款编号',
		couponId: '优惠券ID',
		couponName: '请输入优惠券名称',
		couponText: '优惠券名称',
		couponAmount: '请输入优惠券金额',
		denomination: '面额,例如5',
		babyId: '宝贝ID',
		babyName: '宝贝名称',
		babyCode: '宝贝编码',
		useThreshold: '门槛需高于金额',
		circulation: '需大于等于1000,不超过10万',
		address: '请输入详细地址',
		areaCode: '区号',
		phone: '电话',
		mobileNumber: '手机号码',
		companyName: '公司名称',
		remark: '备注',
		cateSearch1: '类目搜索',
		cateSearch2: '名称/拼音首字母',
		maxLength60: '最多允许输入30个汉字(60字符)',
		selectDate: '选择日期',
		selectCate: '选择分类',
		goodsColor: '选择或输入主色',
		customValue: '请输入自定义值',
		colorType: '颜色分类',
		size: '尺码',
		price: '价格',
		stock: '数量(件)',
		shopCode: '商家编码',
		goodsCode: '商品条形码',
		newFolder: '新建文件夹',
		searchImg: '搜索图片名称',
		closeReason: '请选择关闭理由',
		commentKeyword: '评价关键词/买家昵称/订单编号/商品ID',
		refundsAddress: '请选择退货地址',
		refundsExplain: '请填写退款说明',
		refuseReason: '请选择拒绝原因',
		refuseExplain: '请填写拒绝说明',
		reply: '请输入您的回复内容',
		enterSearch: '请输入，支持回车搜索',
		campaignSearchId: '请输入商品ID/营销ID',
		activitySearch: '请输入活动名称/ID',
		campaignTag: '请选择活动标签',
		maxLength20: '最多20个字',
		searchCrowd: '搜索人群名称',
		searchLabel: '搜索标签名称',
		crowdName: '请为新建的结果人群命名，25 字内',
		policyTitle: '请设置策略标题',
		campaignTitleSearch: '输入活动标题搜索',
		subscribeDesc: '最多144字，内容丰富有特色，将在订阅信息流中配合图片或视频展现给消费者。',
		keywordSearch: '关键词搜索',
		labelName7: '请输入不超过7个汉字',
		commentContent: '输入您想评论的内容',
		babyKeyword: '宝贝关键词',
		minSheet: '至少1张',
		positiveInteger: '请输入正整数'
	},
	validator: {
		title: '请输入标题!',
		moduleName: '请输入模块名称!',
		basicSetup: '请选择基础设置!',
		uploadPic: '请上传图片!',
		wirelessLink: '请输入合法的无线链接!',
		link: '请输入链接!',
		complete: '当前数据为空,请填写完整!',
		shopName: '店铺名称不能为空',
		consentAgreement: '请勾选协议并阅读!',
		address: '详情地址不能为空!',
		region: '请选择省市区!',
		shopDomain1: '请输入域名',
		shopDomain2: '域名只能包含数字，字母和-，并不能以-结尾。',
		pageName: '页面名称不能为空',
		navName: '导航名称不能为空',
		couponName: '请输入优惠券名称',
		cheapRemind: '低价提醒折扣不能为空',
		selectGoods: '请选择商品!',
		limitPerPerson: '请设置每人限领',
		circulation: '请设置发行量!',
		datetime: '请选择时间!',
		advanceDay60: '最长可提前60天创建!',
		validityDay60: '有效期不能超过60天!',
		couponAmount: '优惠券金额不能为空!',
		denominationMin: '面额不得小于1!',
		denominationMax: '面额不得超过5000元!',
		thresholdAmount: '请设置门槛金额!',
		thresholdHigher: '门槛需高于金额!',
		least1: '至少1张!',
		least1000: '至少1000张!',
		most1w: '最多10万张!',
		circulationIncrease: '发行量只能增加不能减少!',
		contacts: '请输入联系人',
		streetText: '区县以下的街道地址最少要4个字，最多不能超过60个字',
		postCode: '请输入邮政编码',
		minLength6: '最小长度6个字符',
		domesticLocation: '国内所在地区必须准确到市(区)!',
		location: '请选择所在地区!',
		areaCodeRange: '电话区号由3到6位数字构成!',
		phoneRange: '电话号由5到10位数字构成!',
		mobileNumber: '请输入正确的手机号码!',
		telephone: '电话和手机不能都为空!',
		templateName: '请输入模板名称',
		deliveryCompany: '请选择快递公司',
		print: '请选择打印项',
		required: '必填字段，不能为空!',
		shippingMode: '请至少选择上面任意一种非货到付款的运送方式!',
		maxLength60: '最多允许输入30个汉字(60字符)',
		minNum: '输入的数字大小必须大于指定的值',
		maxNum: '输入的数字大小不能大于指定的值',
		freightTpl: '请选择运费模板',
		cateMin: '分类最少选择两级!',
		packageName: '套餐名称不能为空',
		packageDesc: '套餐介绍不能为空',
		packageType: '请选择套餐类型',
		packageAttr: '请选择套餐属性',
		packageCoupon: '请选择优惠',
		packageCouponType: '请选择优惠类型',
		packageCouponTime: '请选择时间',
		campaignNameMax20: '活动名称不能为空，最多20个字',
		posterPic: '海报图片不能为空',
		jumpLink: '请选择跳转链接',
		activityTime: '活动时间不能为空',
		imageTable: '请确认本条内容已添加2~9张图片，并且编辑一个带宝贝的标签',
		minSelectGoods: '至少选一个商品',
		exfiltrateUseTime: '开始透出时间需要不晚于券使用开始时间',
		couponMoney: '请填写优惠券金额',
		couponMoneyMax: '需要不大于最大面额5000',
		couponMoneyMin: '需要不小于最低面额5',
		couponGiveNum: '被分享者券的数量应大于等于',
		couponType: '请选择券类型',
		shareNum: '请选择分享人数',
		circulationMsg: '请输入发行量',
		circulationMax: '发行量不能超过10万',
	},
	empty: {
		nodata: '暂无数据',
		material: '这里空空的，没有找到图片',
		unsupported: '该模块暂不支持编辑',
		autoEmerge: '该模块宝贝由系统根据算法自动展现，无需编辑',
		orderList: '没有符合条件的宝贝，请尝试其他搜索条件。',
		noLogisStatus: '无物流动态'
	},
	successMsg: {
		save: '保存成功!',
		publish: '发布成功!',
		goodsShelves: '商品上架成功!',
		goodsOffshelf: '商品下架成功!',
		authenticate: '认证成功!',
		add: '添加成功!',
		set: '设置成功!',
		delete: '删除成功!',
		modify: '修改成功!',
		shipping: '发货成功!',
		operate: '操作成功!',
		submit: '提交成功!',
		create: '创建成功!',
		upload: '上传成功!',
		copy: '复制成功!',
		renameFile: '文件重命名成功!',
		move: '移动成功!',
		close: '关闭成功!',
		setHome: '设为首页成功!',
		reply: '回复成功!',
		collect: '收藏成功!'
	},
	errorMsg: {
		save: '保存失败!',
		containCate: '此分类中还有子分类，不可以删除！',
		complete: '当前数据为空,请填写完整',
		coupon: '请选择优惠券',
		comparePrice: '最低价格不能大于最高价格!',
		compareSales: '最低销量不能大于最高销量!',
		goodsStock: '上架时，宝贝数量不得小于或等于0!',
		seleteShelvesGoods: '请选择上架宝贝!',
		seleteOffShelfGoods: '请选择下架宝贝!',
		goodsDelete: '请选择需要删除商品',
		upload: '上传失败!',
		mobile: '请输入手机号!',
		mobileFormat: '手机号格式错误!',
		verifyCode: '请输入验证码!',
		ownerCard: '请输入身份证号码!',
		ownerCardLength: '身份证号码错误!',
		ownerCardImg: '请上传身份证正反面!',
		shopLogo: '请上传店铺标志',
		agreement: '请阅读并同意协议!',
		submitData: '提交数据不能为空',
		shopIntroMin: '店铺介绍: 长度低于最小值:',
		shopIntroMax: '店铺介绍: 长度超过最大值:',
		shopWideGraph: '图片尺寸不符合要求，请上传宽高为900*506的图片!',
		shopLongGraph: '图片尺寸不符合要求，请上传宽高为900*1600的图片!',
		shopEffect: '店铺印象',
		required: '要求必填!',
		lengthMin: '长度低于最小值:',
		lengthMax: '长度超过最大值:',
		selectOrder: '请选择订单',
		consignee: '收件人姓名不能为空!',
		recipientMobile: '收件人联系方式不能为空!',
		region: '请选择省市区!',
		address: '详细地址不能为空!',
		checkOrder: '请勾选订单后再批量发货',
		logisticsNo: '物流单号不能为空!',
		shipping: '请选择发货信息!',
		shippingOrder: '请选择发货订单!',
		waybillNo: '请填写运单号!',
		returnGoods: '请选择退货信息!',
		tplRegion: '国内模板宝贝地址必须准确到市(区)!',
		repeatSelect: '请不要重复选择同一张图片!',
		maxAddImage: '最多只能添加5张图片。',
		customValue: '与自定义已有枚举值重复，请重新输入。',
		input: '请输入内容',
		goodsSpecs: '此商品或者SKU的价格不允许设定0元',
		newFolderName: '分组名称不能为空!',
		selectFile: '请选择文件!',
		renameFile: '文件重命名失败!',
		close: '关闭失败!',
		batchRefunds: '请选择批量同意退货的订单',
		batchRefund: '请选择批量同意退款的订单',
		maxCustomPage: '自定义页不能超过6个',
		unsupportModulePlace: '此区域不支持放置该模块!',
		unsupportModuleWidth: '此区域不支持该模块宽度!',
		maxModule: '该模块已达可添加上限!',
		maxLayout: '布局最多不能超过5个',
		lastLayout: '最后一个布局不能删除!',
		reply: '回复失败!',
		replyEmpty: '回复内容不能为空!',
		itemSignUp: '商品报名未开始，请耐心等待！',
		selectGoods: '请选择商品!',
		activityStock: '活动库存不能大于商品库存',
		addMainGoods: '请先添加主商品',
		maxFile5: '最多选择5个文件',
		minCombomealPrice: '搭配价格不能低于原价的7折',
		selectMainGoods: '您还未选择主商品',
		minCombomealGoods: '至少选择一个搭配商品7折',
		uploadPackagePic: '必须上传套餐图。',
		ckeckItem: '请勾选商品',
		campaignTag: '请选择有效的活动标签，否则无法创建活动!',
		campaignStartTime: '请选择活动开始时间!',
		campaignEndTime: '请选择活动结束时间',
		campaignTime: '结束时间应晚于当前时间',
		campaignCurrTime: '结束时间应晚于开始时间',
		crowdName: '人群名称不能为空',
		crowdLabel: '还没有添加任何人群标签',
		crowdAttr: '属性值还未设置',
		selectCrowd: '请先选中需要收藏的人群',
		policyCoupon: '请选择优惠券，如果没有请先创建',
		operateText1: '海报',
		operateText2: '店铺首页渠道',
		operateText3: '推广成功',
		activityTitle: '请输入活动标题',
		maxIndex5: '每次最多选择5个指标',
		maxSelectGoods1: '请选择3-9个商品',
		contentDesc144: '请输入1 ~ 144个字符',
		contentEmpty: '内容不能为空',
		appointGoodsMax: '最多选择100个指定商品'
	},
	infoMsg: {
		closeReason: '请选择关闭理由!'
	},
	cancelMsg: {
		publish: '已取消发布!',
		shelves: '已取消上架!',
		offshelf: '已取消下架!',
		delete: '已取消删除!',
		set: '已取消设置!',
		operate: '已取消操作!',
		setHome: '取消首页成功!'
	},
	confirm: {
		title: '提示',
		titlePublish: '发布',
		publishPage: '确认发布当前页面?',
		publishPcPage: '是否确认发布全部电脑端页面?<br/>（本次发布不包含手机端页面）发布成功后，电脑端页面将立即生效！',
		publishPcPageSuccess: '大功告成!已经为您发布所有电脑端页面!',
		shelvesTitle: '立即上架',
		shelvesText: '是否确认上架?',
		batchShelvesTitle: '批量上架',
		offshelfTitle: '立即下架',
		offshelfText: '是否确认下架？宝贝下架后请到“仓库中的宝贝”查看并管理宝贝。',
		batchOffshelfTitle: '批量下架',
		batchDeleteTitle: '批量删除',
		batchDeleteText: '是否确认删除？',
		defaultPageText: '是否确认设置该页面为首页',
		deleteText1: '确定要删除"',
		deleteText2: '"吗?',
		deleteText3: '您确定要删除本条数据吗？',
		deleteText4: '您确定要删除选项"',
		deleteText5: '确定要删除吗？',
		deleteAddress: '确认删除这条地址吗？',
		endCollection: '结束领取后优惠券将不能继续领取，但并不影响已领取的优惠券的使用，请您及时删除相关自主推广信息，确认要进行此操作吗？',
		deleteText: '你确认删除吗?',
		deleteTplText: '确认删除模板？',
		inconsistent: '一口价与有库存的宝贝规格价格不一致,是否继续?',
		deleteTitle: '删除提示',
		deleteFileText: '是否确认删除所选文件？',
		recoveryTitle: '恢复提示',
		deleteRecoveryText: '是否确认恢复所选文件？',
		agreeRefund: '同意订单退款?',
		agreeRefunds: '同意订单退货?',
		agreeOrderRefund: '确认同意将本单金额退还给买家?',
		agreeRefundApply: '确认同意买家退货申请?',
		refuseRefundApply: '是否确认拒绝买家退款申请?',
		openAlipay: '开启真实交易后将无法关闭，确认继续?',
		setHome: '是否确认设置该页面为首页？',
		deleteLayout: '删除布局会将布局内的模块一并删除，您确定要删除吗？',
		signUpSuccess: '恭喜您已成功报名，请点击进到下一步进行商品报名',
		suspendText: '确定要暂停吗？',
		restartText: '确定要重启吗？',
		withdrawalActivityText: '确定要撤出该活动？',
		withdrawalGoodsText: '确定撤出该商品？',
		batchWithdrawalTitle: '撤出商品',
		batchWithdrawalActivity: '确定要把所选商品撤出活动？',
		withdrawalPackage: '确定撤出该套餐？',
		activityFree: '勾选包邮后，参加活动的商品对应运费模板运费将失效。确认设置包邮？',
		setGoodsDiscount: '前往设置商品优惠？',
		deleteCrowdText: '请注意，可能有其他系统使用该人群，请谨慎删除',
		promoteTitle: '推广提示',
		promoteText: '恭喜您，已完成1个渠道的推广配置',
		terminateStrategy: '确认终止改策略吗?',
		remind: '提醒',
		subscribeOfflineText: '请确认是否下线该内容？下线后，用户无法再看到该条订阅内容',
		subscribeDeleteText: '请确认是否删除内容？删除后，该条内容数据无法查看，且用户侧无法看到该条订阅内容',
		beCarefulTit: '注意',
		beCarefulText: '更换图片宽高比将会清空当前已编辑的所有图片，是否仍要继续？',
		deleteReplyText: '是否要删除此回复呢？',
		toppingText: '是否要将该评论置顶显示呢?',
		untoppingText: '是否要将该评论取消置顶显示呢?'
	},
	alert: {
		goodsShelvesTitle: '商品上架',
		goodsShelvesError: '上架失败',
		goodsShelvesText1: '等商品上架成功',
		goodsShelvesText2: '上架时，宝贝数量不得少于或等于0',
		notBatchShipment: '号订单不是等待发货状态, 不支持批量发货',
		finishSet1: '还有',
		finishSet2: '个商品没完成优惠设置，请先完成设置',
		publishText1: '剩余发布次数 ',
		publishText2: ' 次'
	},
	upload: {
		image: '上传图片',
		imageType: '上传图片只能是 JPG/JPEG/PNG 格式!',
		imageType2: '上传图片只能是 JPG/JPEG/PNG/GIF 格式!',
		imageSize3M: '上传图片大小不能超过 3MB!',
		imageSize500K: '上传图片大小不能超过 500K!',
		videoType: '上传格式错误!',
		videoSize5M: '上传视频大小不能超过 5MB!'
	},
	unit: {
		to: '至',
		hour: '小时',
		month: '月',
		piece: '件',
		yuan: '元',
		full: '满',
		start: '起',
		stop: '止',
		pcs: '个',
		day: '天',
		sheet: '张',
		use: '使用',
		freeShipping: '包邮',
		hours: '小时内',
		days: '天内',
		all: '全部',
		number: '数',
		lessThan: '小于',
		greaterThan: '大于',
		people: '人',
		near: '近'
	},
	tag: {
		default: '默认',
		offshelf: '仓库中',
		shelves: '出售中',
		publish: '已发布',
		unpublish: '未发布',
		onlineHome: '线上首页',
		illegal: '违规下架',
		not: '无'
	},
	breadcrumb: {
		home: '首页',
		finishOpenShop: '完成开店',
		shipping: '发货',
		startShipping: '开始发货',
		logisticsDetail: '物流详情',
		seller: '我是卖家',
		marketPlatform: '营销工作台',
		coupon: '优惠券',
		couponInfo: '优惠券详情',
		addShopCoupon: '创建店铺优惠券',
		addGoodsCoupon: '创建商品优惠券',
		addSahreCoupon: '创建分享优惠券',
		orderManage: '交易管理',
		sellGoods: '已卖出的宝贝',
		viewDetail: '查看详情',
		refundList: '退款管理',
		refundInfo: '退款售后详情',
		teaching: '教学',
		goodsManage: '商品管理',
		signUpCampaign: '已报活动',
		combomeal: '搭配宝',
		singletreasure: '单品宝',
		regularOperate: '老客运营',
		dealConversion: '成交转化',
		strategyData: '策略活动数据',
		strategyDataInfo: '运营策略数据详情',
		allWorks: '全部作品',
		contentAnalyse: '单内容分析',
		contentManage: '内容管理'
	},
	result: {
		title: '提示',
		closeShop: '对不起！您的店铺已关闭，请联系您的班级老师。'
	},
	popover: {
		goodsPurchase: '勾选则承诺商品为“海外商品”。',
		stockCount1: '拍下减库存，存在恶拍风险；',
		stockCount2: '付款减库存，存在超卖风险。',
		mobileContent: '将清除之前的手机版宝贝描述，并生成新的'
	},
	filters: {
		orderStatus: { 0: '交易关闭', 11: '待付款', 20: '待发货', 30: '待收货', 40: '交易成功' },
		commentStatus: ['差评','中评','好评'],
		deliveryStatus: ['未发货','已发货'],
		refundStatus1: {1:'退款中',2:'退款成功',3:'退款失败',4:'拒绝退款',5:'退款关闭',10:'买家取消退款'},
		refundStatus2: {1:'退款待处理',2:'待买家退货',3:'待商家收货',4:'退款成功'},
		refundStatus3: {1:'请处理退货申请',2:'请等待买家退货',3:'请确认收货'},
		goodsStatus: { 1: '仅退款',2: '仅退款', 3: '退货退款' },
		goodsType: {0:'仓库中',1:'出售中',2: '违规中'},
		accordWith: {0:'不符合',1:'符合条件'},
		itemSignUpStatus: {0:'草稿(报名未完成)',1:'审核通过',2: '审核拒绝'},
		campaignStatusText: {1:'', 2: '商家报名中',3: '商品报名中', 4: '活动预热中', 5: '活动售卖中',6:'已结束'},
		packageType: {1:'自由搭配',2:'固定搭配' },
		packageStatus: {1:'已暂停',0:'进行中' },
		packageGoods: {0:'子商品',1:'主商品' },
		packageCoupon: {0:'无',1:'搭配价' },
		dateCompare: {1:'较前1日',3:'较前7日',4:'较前30日'},
		discountLevel: {1: '商品级', 2: 'SKU级'},
		discountType: {1: '打折', 2: '减钱', 3: '促销价'},
		singletreasureType: {1: '选择活动商品', 2: '设置商品优惠'},
		activityStatus: {1: '已暂停',2: '未开始',3: '进行中',4: '已结束',5: '已删除'},
		crowdTagsDesc: {
			'sex': '根据用户网购数据计算得出',
			'region': '根据最近180天用户收货地址计算预测得出',
			'buy': '选定自然日内，购买过本店商品的消费者（购买以支付成功为准，包括支付后退款）。',
			'cart_goods': '客户当前购物车内存在下列商品中的任意一个',
			'no_buy': '选定自然日内，没有购买过本店商品的消费者（购买以支付成功为准，包括支付后退款）。',
			'collect': '选定自然日内，收藏过本店铺的消费者',
			'no_add_cart': '选定自然日内，没有加购过本店商品的消费者。',
			'visit': '选定自然日内，访问过本店的消费者。',
			'add_cart': '选定自然日内，加购过本店商品的消费者。',
			'no_collect': '选定自然日内，没有收藏过本店铺的消费者',
			'no_visit': '选定自然日内，没有访问过本店的消费者。',
			'buy_goods': '客户近720天内购买过下列商品中的任意一个（购买以支付成功为准，包括支付后退款）',
			'last_trade_time': '在本店最近一次成功交易（订单完结）的时间范围',
			'first_order_time': '客户在本店的首次下单时间范围 ',
			'pay_num': '客户在店铺近两年付款的笔数',
			'per_ticket_sales': '单个客户在店铺近两年的付款总金额/总付款次数',
			'trade_amount': '客户在店铺近两年成功交易（订单完结）的总金额',
			'trade_num': '客户在店铺近两年成功交易（订单完结）的笔数',
			'repo_cycle': '（单个客户在店铺的最近付款时间-首次付款时间）/（付款次数-1）',
			'last_pay_time': '在本店最近一次付款的时间范围',
			'pay_amount': '客户在店铺近两年付款的总金额',
			'first_order': '选定自然日内，在店铺的首次下单的消费者'
		},
		customerType: {'new_customer':'新客','old_customer':'老客','fans_num':'粉丝'},
		crowdType: {0:'系统人群',1:'自定义人群'},
		crowdSex: {1: '女',2:'男'},
		unit: {1:'次',2:'元',3:'天'},
		crowdCouponType: {0:'未设置人群，优惠券暂不可用',1:'新客',2:'老客'},
		strategyStatus: {0:'执行中',1:'已结束',2:'已终止'},
		strategyItemStatus: {0:'待执行',1:'进行中',2:'已结束',3:'已终止'},
		subscribeType: {0:'多品上新',1:'图文搭配',2:'图文评测',3:'清单'},
		subscribeSatus: {0:'草稿',1:'已发布',2:'已下架'},
		subscribeItem: {'product_update':'多品上新','graphics_collocate':'图文搭配','graphics_evaluate':'图文评测','inventory':'清单'},
		subscribeName: {'product_update':'上新','graphics_collocate':'搭配','graphics_evaluate':'评测','inventory':'清单'},
		subscribeName2: {'product_update':'上新','inventory':''},
		subscribeTitleDesc: {'product_update':'最大长度144字，建议输入 50 字左右，50字在买家侧展示效果最佳','inventory':'建议您描述宝贝卖点/权益/信息等内容'},
		subscribeGoodsDesc: {'product_update':'选择3-9个需要发给买家的上新宝贝。可发布的宝贝需为15天之内，符合行业新品规则，首次上架的新品宝贝。','inventory':'选择3-9个需要发给买家的宝贝。建议您有明确清单主题（如促销宝贝清单，热销排行榜清单），这样转化更好哦'},
		couponType: {1:'店铺券',2:'商品券'},
		circulation: {0:'需大于等于1000,不超过10万',1:'需大于等于1,不超过10万'}
	}
}
export default seller